import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from '../../routes';
import { ILoginUser, IUserStore } from '../types';
import { checkResponse, request } from '../utils';

export const checkUserAuth = () => (dispatch: any) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    dispatch(getUser());
  }
};

export const loginUser = createAsyncThunk('loginUser',  (user: ILoginUser) => {
  const payload = {
    identifier: user.identifier,
    password: user.password,
  };

  return request(`${BASE_URL}/auth/local`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(payload),
  }).then((data) => data);
});

const getUserRequest = () =>
  fetchWithRefresh(`${BASE_URL}/users/me`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
  });

export const refreshToken = async () => {
  try {
    const res = await fetch(`${BASE_URL}/auth/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ token: localStorage.getItem('refreshToken') }),
    });
    return await checkResponse(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchWithRefresh = async (url: string, options: any) => {
  try {
    const res = await fetch(url, options);
    return await checkResponse(res);
  } catch (err: any) {
    if (err.message === 'jwt expired') {
      const refreshData = await refreshToken();
      if (!refreshData.success) {
        Promise.reject(refreshData);
      }
      localStorage.setItem('refreshToken', refreshData.refreshToken);

      let authToken = refreshData.accessToken;
      authToken = authToken.split('Bearer ')[1];
      if (authToken) {
        // Сохраняем токен в куку token
        localStorage.setItem('accessToken', authToken);
      }

      options.headers.Authorization = 'Bearer ' + authToken;
      const res = await fetch(url, options);
      return await checkResponse(res);
    } else {
      return Promise.reject(err);
    }
  }
};

export const getUser = createAsyncThunk('user/getUser', async () => {
  return await getUserRequest();
});

const initialState: IUserStore = {
  loadingStatus: 'idle',
  error: null,
  user: {
    id: 0,
    username: '',
    email: '',
    provider: '',
    confirmed: false,
    blocked: false,
    createAt: '',
    updatedAt: '',
  },
};

export const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state = initialState) => {
      state.loadingStatus = 'logout';
      state.error = null;
      state.user = {
        id: 0,
        username: '',
        email: '',
        provider: '',
        confirmed: false,
        blocked: false,
        createAt: '',
        updatedAt: '',
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loadingStatus = 'isLoading';
        state.error = null;
        state.user = {
          id: 0,
          username: '',
          email: '',
          provider: '',
          confirmed: false,
          blocked: false,
          createAt: '',
          updatedAt: '',
        };
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(loginUser.fulfilled, (state, action) => {
        if (action?.payload?.error) {
          state.loadingStatus = 'serverError';
          state.error = action?.payload?.error?.message;
        }
        if (action?.payload?.user) {
          let authToken = action.payload.jwt;
          let refreshToken = action.payload.refresgToken;
          if (authToken) {
            localStorage.setItem('accessToken', authToken);
          }
          if (refreshToken) {
            localStorage.setItem('refreshToken', refreshToken);
          }
          // Добавляем пользователя
          state.user = action.payload.user;
          state.loadingStatus = 'success';
          state.error = null;
        }
      })
      // Вызывается в случае ошибки
      .addCase(loginUser.rejected, (state, action) => {
        state.loadingStatus = 'failed';
        state.error = action.error;
        state.user = {
          id: 0,
          username: '',
          email: '',
          provider: '',
          confirmed: false,
          blocked: false,
          createAt: '',
          updatedAt: '',
        };
      })
      .addCase(getUser.pending, (state) => {
        state.loadingStatus = 'gettingDataIsLoading';
        state.error = null;
        state.user = {
          id: 0,
          username: '',
          email: '',
          provider: '',
          confirmed: false,
          blocked: false,
          createAt: '',
          updatedAt: '',
        };
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(getUser.fulfilled, (state, action) => {
        state.loadingStatus = 'success';
        state.error = null;
        state.user = action.payload;
      })
      // Вызывается в случае ошибки
      .addCase(getUser.rejected, (state, action) => {
        state.loadingStatus = 'jwt expired';
        state.error = action.error;
        state.user = {
          id: 0,
          username: '',
          email: '',
          provider: '',
          confirmed: false,
          blocked: false,
          createAt: '',
          updatedAt: '',
        };
      });
  },
});


export const { logout } = authSlice.actions;
