import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from '../../routes';

export const sendFeedback = createAsyncThunk('feedback/sendFeedback', (values: any) => {
  return fetch(`${BASE_URL}/feedbacks`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
    body: JSON.stringify({ data: values }),
  })
    .then((res) => res.json())
    .then((data) => {
      return data.data;
    });
});

const initialState: any = {
  loadingStatus: 'idle',
  error: null,
};

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendFeedback.pending, (state) => {
        state.loadingStatus = 'isLoading';
        state.error = null;
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(sendFeedback.fulfilled, (state, action) => {
        state.loadingStatus = 'successSend';
        state.error = null;
      })
      // Вызывается в случае ошибки
      .addCase(sendFeedback.rejected, (state, action) => {
        console.log('error');
        state.loadingStatus = 'failed';
        state.error = action.error;
      });
  },
});
