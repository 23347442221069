import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Input, Select } from 'antd';
import { RootState } from '../../services/store/store';
import { IService } from '../../services/types';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'multiselect' | 'text';
  record: IService;
  index: number;
  children: React.ReactNode;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const tags = useSelector((store: RootState) => store.tags.tags);
  const [tagsData, setTegsData] = useState(tags);
  const handleChange = (value: string) => {
  };
  const inputNode =
    inputType === 'multiselect' ? (
      <Select
        mode="tags"
        style={{ width: '100%' }}
        placeholder="Добавьте теги"
        onChange={handleChange}
        options={tagsData.map((tag) => ({
          label: tag.attributes.name,
          value: tag.id,
        }))}
      />
    ) : (
      <Input placeholder="Заполните поле" />
    );

  useEffect(() => {
    setTegsData(tags);
  }, []);

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Заполните поле!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
