import { AppDispatch, RootState } from '../../services/store/store';

import React, { useEffect, useState } from 'react';
import { Button, Col, Flex, List, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  getPoint,
  deletePointOperator,
  addPointOperator,
} from '../../services/store/points';
import servicePointFormStyles from './EditPointForm.module.css';

interface Props {
  pointId: number;
}

const App: React.FC<Props> = ({ pointId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const currentPoint = useSelector((store: RootState) => store.currentPoint.point);
  const [currentPointData, setCurrentPointData] = useState(currentPoint);

  const users = useSelector((store: RootState) => store.users.users);
  const [usersData, setUsersData] = useState<any>([]);

  const handleAdd = (user: any) => {
    if (currentPointData) {
      dispatch(
        addPointOperator({
          id: currentPointData?.id,
          users: [...currentPointData?.attributes.users.data.map((user) => user.id), user.id],
        })
      );
    }
  };

  const handleDelete = (userToDelete: any) => {
    dispatch(
      deletePointOperator({
        id: currentPointData?.id,
        users: currentPointData?.attributes.users.data
          .filter((user) => user.id !== userToDelete.id)
          .map((user) => user.id),
      })
    );
  };

  useEffect(() => {
    dispatch(getPoint(pointId));
  }, []);

  useEffect(() => {
    if (currentPoint) {
      setCurrentPointData(currentPoint);
      const currentPointUsers = currentPoint?.attributes.users.data.map((user) => user.id);
      const newUsers = users.filter((item: any) => !currentPointUsers?.includes(item.id));
      setUsersData(newUsers);
    }
  }, [currentPoint]);

  return (
    <div className={servicePointFormStyles.operatorsWrapper}>
      <Row style={{ marginBottom: 30 }}>
        <Col span={12}>
          <List
            size="small"
            header={<div>Пользователи</div>}
            bordered
            dataSource={usersData}
            renderItem={(user: any) => (
              <List.Item>
                <Flex justify="space-between" align="center" style={{ width: '100%' }}>
                  <Typography.Text>{user.attributes.username}</Typography.Text>
                  <Button
                    type="text"
                    shape="round"
                    icon={<PlusOutlined />}
                    size="small"
                    onClick={() => handleAdd(user)}
                  ></Button>
                </Flex>
              </List.Item>
            )}
          />
        </Col>
        <Col span={12}>
          {currentPointData?.attributes.users ? (
            <List
              size="small"
              header={
                <div>{`Операторы точки обслуживания ${currentPointData?.attributes.name}`}</div>
              }
              bordered
              dataSource={currentPointData.attributes.users?.data.filter(
                (user) => user.attributes.role?.data.attributes.type !== 'owner'
              )}
              renderItem={(user) => (
                <List.Item>
                  <Flex justify="space-between" align="center" style={{ width: '100%' }}>
                    <Typography.Text>{user.attributes.username}</Typography.Text>
                    <Button
                      type="text"
                      shape="round"
                      icon={<DeleteOutlined />}
                      size="small"
                      onClick={() => handleDelete(user)}
                    ></Button>
                  </Flex>
                </List.Item>
              )}
            />
          ) : (
            <p style={{ padding: '0 20px' }}>К текущей точке не привязано ни одного оператора</p>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default App;
