import { Badge, Space, Tooltip } from 'antd';
import { Cell, Pie, PieChart } from 'recharts';

import StatisticStyles from './Statistics.module.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/store/store';
import { useEffect, useState } from 'react';

const COLORS = [
  '#F05F23',
  '#01A2E8',
  '#F5F5F5',
  '#C4C4C4',

];

const PieBlock = () => {
  const [dataSet, setDataSet] = useState<any>([]);

  const statistics = useSelector((store: RootState) => store.statistics.statistics);

  useEffect(() => {
    if (statistics.attributes.amountByMonths) {
      setDataSet(statistics.attributes.amountByPoints);
    }
  }, [statistics]);

  return (
    <div className={StatisticStyles.barChartWrapper}>
      <h4 className={StatisticStyles.pieTitle}>Топ {statistics.attributes.amountByMonths.length} точки по прибыли за последние 3 месяца</h4>
      <div className={StatisticStyles.pie}>
        <PieChart width={160} height={160}>
          <Pie dataKey="amount" data={dataSet} innerRadius={60} outerRadius={80} fill="#82ca9d">
            {dataSet.map((entry: any, index: any) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
        <Space direction="vertical" className={StatisticStyles.pieScroll}>
          {dataSet.map((entry: any, index: any) => (
            <div className={StatisticStyles.pieInfoItem}>
              <Badge
                color={COLORS[index % COLORS.length]}
                className={StatisticStyles.pieBadgeText}
                text={`${entry.name} - ${entry.amount} ₽`}
              />
            </div>
          ))}
        </Space>
      </div>
    </div>
  );
};

export default PieBlock;
