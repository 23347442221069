import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Button, ConfigProvider, Dropdown, Layout, MenuProps } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import { MenuOutlined, UserOutlined } from '@ant-design/icons';
import locale from 'antd/locale/ru_RU';

import { AppDispatch } from './services/store/store';

import ForgotPassword from './pages/ResetPassword/ForgotPassword';
import Operators from './pages/Operators/Operators';
import { ProtectedRouteElement } from './components/ProtectedRouteElement/ProtectedRouteElement';
import Orders from './pages/Orders/Orders';
import Logo from './images/logo.svg';

import { checkUserAuth, logout } from './services/store/auth';

import Login from './pages/Login/Login';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ServiceList from './pages/ServiceList/ServiceList';

import Sidebar from './components/Sidebar/Sidebar';
import { getPoints } from './services/store/points';
import { getUsers } from './services/store/operators';
import Statistics from './pages/Statistics/Statistics';
import { getOrders } from './services/store/orders';
import ForgotPasswordInfo from './pages/ResetPassword/ForgotPasswordInfo';

const headerStyle: React.CSSProperties = {
  color: '#fff',
  height: 64,
  paddingInline: 48,
  lineHeight: '64px',
  backgroundColor: '#FAFAFA',
  display: 'flex',
  alignItems: 'center',
};

const contentStyle: React.CSSProperties = {
  minHeight: 120,
  lineHeight: '120px',
  backgroundColor: '#FAFAFA',
};

const layoutStyle = {
  borderRadius: 8,
  overflow: 'hidden',
  height: '100vh',
  backgroundColor: 'rgb(250, 250, 250',
};

const App: React.FC = () => {
  const [collapsed, setCollapsed] = useState(true);

  const location = useLocation();

  const dispatch = useDispatch<AppDispatch>();

  const isInnerScreen =
    location.pathname !== '/login' &&
    location.pathname !== '/forgot-password' &&
    location.pathname !== '/reset-password' &&
    location.pathname !== '/forgot-password-info';

  const items: MenuProps['items'] = [
    // {
    //   label: <Link to="/profile">Профиль</Link>,
    //   key: '0',
    // },

    // {
    //   type: 'divider',
    // },
    {
      label: (
        <a href="https://www.antgroup.com" onClick={(e) => handleLogout(e)}>
          Выйти
        </a>
      ),
      key: '2',
    },
  ];

  const handleLogout = (e: any) => {
    e.preventDefault();
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    dispatch(logout());
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    setCollapsed(true);
  }, [location.pathname]);

  useEffect(() => {
    dispatch(checkUserAuth());
    dispatch(getPoints());
    dispatch(getUsers());
    dispatch(getOrders());
  }, []);

  return (
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          colorPrimary: '#2290d6',
          colorLink: '#2290d6',
          borderRadius: 2,
          colorBgContainer: 'white',
        },
        components: {
          Button: {
            defaultColor: '#01A2E8',
            defaultBorderColor: '#01A2E8',
          },
        },
      }}
    >
      <Layout style={layoutStyle}>
        {!isInnerScreen && (
          <div
            style={{
              margin: '20px 60px',
              display: 'inline-flex',
            }}
          >
            <img src={Logo} alt="Лого" />
          </div>
        )}

        {isInnerScreen && (
          <Header style={headerStyle}>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-33px' }}>
              <Button className="menu-button" onClick={toggleCollapsed}>
                <MenuOutlined />
              </Button>
              <div style={{ marginLeft: '20px', display: 'inline-flex' }}>
                <img src={Logo} alt="Лого" />
              </div>
            </div>

            <Dropdown menu={{ items }} trigger={['click']}>
              <Button shape="circle" className="profile-button">
                <UserOutlined style={{ fontSize: '22px', color: '#4C4C4C' }} />
              </Button>
            </Dropdown>
          </Header>
        )}
        <Layout>
          {isInnerScreen && <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />}

          <Content style={contentStyle}>
            <Routes>
              <Route
                path="/login"
                element={<ProtectedRouteElement onlyUnAuth={true} element={<Login />} />}
              />

              <Route
                path="/operators"
                element={<ProtectedRouteElement onlyUnAuth={false} element={<Operators />} />}
              />
              <Route
                path="/statistics"
                element={<ProtectedRouteElement onlyUnAuth={false} element={<Statistics />} />}
              />
              <Route
                path="/orders"
                element={<ProtectedRouteElement onlyUnAuth={false} element={<Orders />} />}
              />
              <Route
                path="/"
                element={<ProtectedRouteElement onlyUnAuth={false} element={<ServiceList />} />}
              />
              <Route
                path="/forgot-password"
                element={<ProtectedRouteElement onlyUnAuth={true} element={<ForgotPassword />} />}
              />

              <Route
                path="/forgot-password-info"
                element={
                  <ProtectedRouteElement onlyUnAuth={true} element={<ForgotPasswordInfo />} />
                }
              />
              <Route
                path="/reset-password"
                element={<ProtectedRouteElement onlyUnAuth={true} element={<ResetPassword />} />}
              />

              {/* <Route path="/profile" element={<Profile />} /> */}
            </Routes>
          </Content>
        </Layout>

        {/* {hasUnverifiedPatients && <NotVerifiedPatientsAlert />} */}
      </Layout>
    </ConfigProvider>
  );
};

export default App;
