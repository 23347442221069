import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'antd';

import resetPasswordStyles from './ResetPassword.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../services/store/store';
import { setNewPassword } from '../../services/store/resetPassword';

type FieldType = {
  password: string;
  passwordConfirmation: string;
  code: string;
};

const ResetPassword: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const passwordStatus = useSelector((store: RootState) => store.resetPassword.loadingStatus);

  const onFinish = (values: { password: string; passwordConfirmation: string; code: string }) => {
    dispatch(setNewPassword(values));
  };
  const [form] = Form.useForm();

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    if (passwordStatus === 'successSetNewPassword') {
      navigate('/');
    }
    if (passwordStatus === 'failedSetNewPassword') {
      form.setFields([
        {
          name: 'code',
          errors: ['Неверный код из письма'],
        },
      ]);
    }
  }, [passwordStatus]);

  useEffect(() => {
    const url = window.location.href;
    const link = new URL(url);
    const code = link.searchParams.get('code');
    if (code) {
      form.setFieldsValue({
        code: code,
      });
    }
  }, []);

  return (
    <div className={resetPasswordStyles.formWrapper}>
      <div className={resetPasswordStyles.form}>
        <h1 className={resetPasswordStyles.title}>Восстановление пароля</h1>
        <Form
          form={form}
          name="login"
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size="large"
          layout="vertical"
          className={resetPasswordStyles.formInner}
        >
          <Form.Item<FieldType>
            label="Новый пароль"
            name="password"
            rules={[{ required: true, message: 'Пожалуйста, введите новый пароль' }]}
            className={resetPasswordStyles.field}
          >
            <Input.Password placeholder="Введите новый пароль" />
          </Form.Item>
          <Form.Item<FieldType>
            label="Подтверджение нового пароля"
            name="passwordConfirmation"
            rules={[
              { required: true, message: 'Пожалуйста, подтвердите новый пароль' },
              {
                validator: async (_, value) => {
                  if (form.getFieldValue('password') === value) {
                    return true;
                  }
                  throw new Error('Введенные пароли не совпадают');
                },
              },
            ]}
            dependencies={['password']}
            className={resetPasswordStyles.field}
          >
            <Input.Password placeholder="Подтвердите новый пароль" />
          </Form.Item>

          <Form.Item<FieldType>
            label="Код из письма"
            name="code"
            rules={[{ required: true, message: 'Пожалуйста, введите код из письма' }]}
            className={resetPasswordStyles.field}
          >
            <Input placeholder="Введите код из письма" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className={resetPasswordStyles.resetButton}>
              Установить новый пароль
            </Button>
          </Form.Item>
        </Form>
        <p className={resetPasswordStyles.linkToLogin}>
          Вспомнили пароль?{' '}
          <Button type="link" onClick={() => navigate('/login')}>
            Войти
          </Button>
        </p>
      </div>
    </div>
  );
};

export default ResetPassword;
