import { SerializedError } from '@reduxjs/toolkit';
import WorkingHours from '../components/WorkingHours/WorkingHours';

export interface ILoginUser {
  identifier: string;
  password: string;
}

export interface IAuthStore {
  loadingStatus: string;
  error: SerializedError | null;
  user: {
    identifier: string;
    password?: string;
  };
}

export interface IUserStore {
  loadingStatus: string;
  error: SerializedError | null;
  user: {
    id: number;
    username?: string;
    email: string;
    provider: string;
    confirmed: boolean;
    blocked: boolean;
    createAt: string;
    updatedAt: string;
  };
}

export interface IPartner {
  data: {
    id: number;
    attributes: {
      name: string;
      email: string;
      description: string;
      link: string;
      createdAt: string;
      updatedAt: string;
      publishedAt: string;
    };
  };
}

export interface IService {
  id: number | string;
  attributes: {
    name: string;
    price: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    service_tags?: {
      data: ITag[];
    };
    points: number[];
  };
  serviceTags: {
    data: ITag[];
  };
}

export interface IUser {
  id: number;
  attributes: {
    username: string;
    email: string;
    role: {
      data: {
        id: 3;
        attributes: {
          name: string;
          description: string;
          type: string;
          createdAt: string;
          updatedAt: string;
        };
      };
    };
  };
}

export interface IWorkingHours {
  monday: boolean;
  mondayTime: string[];
  tuesday: boolean;
  tuesdayTime: string[];
  wednesday: boolean;
  wednesdayTime: string[];
  thursday: boolean;
  thursdayTime: string[];
  friday: boolean;
  fridayTime: string[];
  saturday: boolean;
  saturdayTime: string[];
  sunday: boolean;
  sundayTime: string[];
  everyday: boolean;
}

export enum Status {
  pending = 'Pending',
  approved = 'Approved',
  rejected = 'Rejected',
}

export interface IPoint {
  key?: number;
  id: number;
  attributes: {
    name: string;
    type: string;
    address: string;
    serviceOperation: string;
    phone: string;
    email: string;
    latitude: string;
    longitude: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    partner: IPartner;
    services: {
      data: IService[];
    };
    users: {
      data: IUser[];
    };
    workingHours: IWorkingHours;
    status: Status;
    wasPublished: boolean;
    rejectReason: string | null;
  };
}

export interface IPointStore {
  loadingStatus: string;
  error: SerializedError | null;
  points: IPoint[] | [];
}

export interface ITag {
  id: string;
  attributes: {
    name: string;
    level: number;
    sortWeight: number;
    showGPN: boolean;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
  };
  name: string;
}

export interface ITagStore {
  loadingStatus: string;
  error: SerializedError | null;
  tags: ITag[];
}

export interface ICurrentPointStore {
  loadingStatus: string;
  error: SerializedError | null;
  point: IPoint | null;
}

export interface IServicesStore {
  loadingStatus: string;
  error: SerializedError | null;
  services: IService[];
}

export interface IEditableService {
  id: number | string;
  name?: string;
  service_tags?: string[];
  price?: string;
  points: number[];
}

export interface ICart {
  id: number;
  attributes: {
    name: string;
    price: string;
    quantity: string;
    amount: string;
    createdAt: string;
    updatedAt: string;
  };
}

export interface IOrder {
  id: number | string;
  attributes: {
    status: string;
    partnerId: IPartner;
    pointId: {
      data: IPoint;
    };
    cart: {
      data: ICart[];
    };
    amount: string;
    gpnUUID: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
  };
}
export interface IOrdersStore {
  loadingStatus: string;
  error: SerializedError | null;
  orders: IOrder[];
}

export interface IResetPasswordStore {
  loadingStatus: string;
  error: SerializedError | null;
}

export interface IPointData {
  pointId: number;
  pointName: string;
  ordersCount: string;
  amount: number;
}

export interface IStatistics {
  attributes: {
    amountByMonths: { monthNumber: number; amount: number }[];
    amountByPoints: [{ pointId: number; name: string; amount: number }];
    pointsData: IPointData[];
    amountTotal: number;
    amountTotalMonth: number;
    countTotal: number;
    countTotalMonth: number;
  };
}

export interface IStatisticsStore {
  loadingStatus: string;
  error: SerializedError | null;
  statistics: IStatistics;
}
