import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { authSlice } from './auth';
import { pointSlice, currentPointSlice } from './points';
import { serviceSlice } from './services';
import { usersSlice } from './operators';
import { tagsSlice } from './tags';
import { ordersSlice } from './orders';
import { resetPasswordSlice } from './resetPassword';
import { feedbackSlice } from './feedback';
import { statisticsSlice } from './statistics';

export const rootReducer = combineReducers({
  auth: authSlice.reducer,
  points: pointSlice.reducer,
  currentPoint: currentPointSlice.reducer,
  services: serviceSlice.reducer,
  users: usersSlice.reducer,
  tags: tagsSlice.reducer,
  orders: ordersSlice.reducer,
  resetPassword: resetPasswordSlice.reducer,
  feedback: feedbackSlice.reducer,
  statistics: statisticsSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
