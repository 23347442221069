import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Input, Select, Spin, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../services/store/store';
import { IOrder } from '../../services/types';

import { getFilteredOrders, getOrders } from '../../services/store/orders';
import dayjs from 'dayjs';
import OrderInfo from '../../components/OrderInfo/OrderInfo';

import orderStyles from './Orders.module.css';

const headerHeight = '300px';

const Orders: React.FC = () => {
  const [data, setData] = useState<IOrder[]>();
  const [dateRange, setDateRange] = useState<any>([null, null]);
  const [open, setOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<IOrder | null>(null);

  const [selectedPoint, setSelectedPoint] = useState('');

  const orders = useSelector((store: RootState) => store.orders.orders);
  const ordersStatus = useSelector((store: RootState) => store.orders.loadingStatus);

  const points = useSelector((store: RootState) => store.points.points);

  const dispatch = useDispatch<AppDispatch>();

  const { RangePicker } = DatePicker;

  const handleOpenOrderInfo = (order: IOrder) => {
    setCurrentOrder(order);
    setOpen(true);
  };
  const columns: ColumnsType<IOrder> = [
    { title: 'ID', dataIndex: ['id'], key: 'id', width: '15%' },

    {
      title: 'Точка обслуживания',
      dataIndex: ['partner'],
      key: 'name',
      width: '30%',
    },
    {
      title: 'Сумма',
      dataIndex: ['attributes', 'amount'],
      key: 'price',
      width: '150px',
      render: (_: any, record: IOrder) => {
        return <>{`${record.attributes.amount} ₽`}</>;
      },
    },
    {
      title: 'Дата создания',
      dataIndex: ['attributes', 'createdAt'],
      key: 'date',
      width: '150px',
      render: (_: any, record: IOrder) => {
        return <>{dayjs(record.attributes.createdAt).format('DD.MM.YYYY')}</>;
      },
    },
    {
      title: 'Статус',
      dataIndex: '',
      key: 'x',
      width: '100px',
      render: (_: any, record: IOrder) => {
        return (
          <>
            {record.attributes.status === 'payment_pending' && <Tag color="processing">Ожидание</Tag>}
            {record.attributes.status === 'canceled' && <Tag color="warning">Отменено</Tag>}
            {record.attributes.status === 'draft' && <Tag color="default">Черновик</Tag>}
            {record.attributes.status === 'succeeded' && <Tag color="success">Оплачено</Tag>}
            {record.attributes.status === 'failed' && <Tag color="error">Ошибка</Tag>}
          </>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '50px',
      render: (_: any, record: IOrder) => {
        return (
          <div>
            <Button size="small" type="link" onClick={() => handleOpenOrderInfo(record)}>
              <EyeOutlined style={{ fontSize: '20px' }} />
            </Button>
          </div>
        );
      },
    },
  ];

  const handleSearch = (e: any) => {
    const flattenedData = orders.map((item: any) => ({
      ...item,
      partner: _.get(item, 'attributes.pointId.data.attributes.name', []),
    }));
    let filteredPoints = flattenedData?.filter((item) =>
      item.attributes.pointId.data.attributes.name.toLowerCase().includes(e.target.value)
    );

    setData(filteredPoints);
  };

  useEffect(() => {
    if (orders?.length) {
      const flattenedData = orders.map((item: any) => ({
        ...item,
        partner: _.get(item, 'attributes.pointId.data.attributes.name', []),
      }));
      setData(flattenedData);
    } else {
      setData([]);
    }
  }, [orders]);

  useEffect(() => {
    if (selectedPoint || (dateRange && dateRange[0])) {
      dispatch(
        getFilteredOrders({
          dateFrom:
            dateRange && dateRange[0] ? dayjs(dateRange[0]).subtract(1, 'day').format('YYYY-MM-DDT00:00:00') : null,
          dateTo:
            dateRange && dateRange[1] ? dayjs(dateRange[1]).format('YYYY-MM-DDT23:59:59') : null,
          pointId: selectedPoint ?? null,
        })
      );
    } else {
      dispatch(getOrders());
    }
  }, [dateRange, selectedPoint]);

  return (
    <div className="content-wrapper">
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h1 className="page-title">Заказы</h1>
        <Input
          size="middle"
          placeholder="Поиск заказа"
          prefix={<SearchOutlined />}
          style={{ margin: '0 15px 0 0', width: '260px' }}
          onChange={(e) => handleSearch(e)}
        />
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          margin: '15px 0',
        }}
      >
        <RangePicker
          placeholder={['Начало', 'Конец']}
          size="middle"
          format="DD.MM.YYYY"
          changeOnBlur
          onCalendarChange={(val) => {
            setDateRange(val);
          }}
          style={{ marginRight: '20px', width: '240px' }}
          allowClear
        />
        <Select
          allowClear
          placeholder="Фильтр по точке"
          style={{ width: 240 }}
          size="middle"
          onChange={(point) => setSelectedPoint(point)}
          options={points.map((point) => ({
            label: point.attributes.name,
            value: point.id,
          }))}
        />
      </div>
      {ordersStatus === 'isLoading' ? (
        <div className={orderStyles.spinWrapper}>
          <Spin />
        </div>
      ) : (
        <div style={{ position: 'relative' }}>
          <Table
            size="small"
            pagination={{ pageSize: 15 }}
            columns={columns}
            scroll={{
              x: `600px`,
              y: `calc(100vh - ${headerHeight})`,
            }}
            dataSource={data?.map((order: IOrder) => ({ ...order, key: order.id }))}
          />
        </div>
      )}

      {currentOrder && <OrderInfo order={currentOrder} open={open} setOpen={setOpen} />}
    </div>
  );
};

export default Orders;
