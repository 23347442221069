import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from '../../routes';
import { IPointStore, ICurrentPointStore } from '../types';

export const getPoints = createAsyncThunk('points/getPoints', () => {
  return fetch(`${BASE_URL}/points?populate=*&publicationState=preview&sort=updatedAt:desc`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data.data;
    });
});

export const getPoint = createAsyncThunk('points/getPoint', (id: number) => {
  return fetch(
    `${BASE_URL}/points/${id}?populate[partner]=true&populate[users]=true&populate[services][populate][0]=service_tags`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
    }
  )
    .then((res) => res.json())
    .then((data) => {
      return data.data;
    });
});

export const editPoint = createAsyncThunk('points/editPoint', (values: any) => {
  const { id, ...rest } = values;
  const payload = { data: rest };

  return fetch(`${BASE_URL}/points/${id}?populate=*`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      return data.data;
    });
});

export const addPoint = createAsyncThunk('points/addPoint', (values: any) => {
  return fetch(`${BASE_URL}/points`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
    body: JSON.stringify({ data: values }),
  })
    .then((res) => res.json())
    .then((data) => {
      return data.data;
    });
});

export const copyPoint = createAsyncThunk('points/copyPoint', (id: string | number) => {
  return fetch(`${BASE_URL}/points/${id}/clone`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data.data;
    });
});

export const deletePoint = createAsyncThunk('points/deletePoint', (id: number) => {
  return fetch(`${BASE_URL}/points/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data.data;
    });
});

export const deletePointOperator = createAsyncThunk('points/deletePointOperator', (values: any) => {
  const { id, ...rest } = values;
  const payload = { data: rest };

  return fetch(`${BASE_URL}/points/${id}?populate=*`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      return data.data;
    });
});

export const addPointOperator = createAsyncThunk('points/addPointOperator', (values: any) => {
  const { id, ...rest } = values;
  const payload = { data: rest };

  return fetch(`${BASE_URL}/points/${id}?populate=*`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      return data.data;
    });
});

const initialState: IPointStore = {
  loadingStatus: 'idle',
  error: null,
  points: [],
};

export const pointSlice = createSlice({
  name: 'point',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPoints.pending, (state) => {
        state.loadingStatus = 'isLoading';
        state.error = null;
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(getPoints.fulfilled, (state, action) => {
        if (action?.payload?.error) {
          alert(action?.payload?.error.message);
          state.loadingStatus = 'serverError';
          state.error = action?.payload?.error;
        }
        if (action?.payload) {
          // Добавляем пользователя
          state.points = action.payload;
          state.loadingStatus = 'success';
          state.error = null;
        }
      })
      // Вызывается в случае ошибки
      .addCase(getPoints.rejected, (state, action) => {
        console.log('error');
        state.loadingStatus = 'failed';
        state.error = action.error;
        state.points = [];
      })
      .addCase(editPoint.pending, (state) => {
        state.loadingStatus = 'isLoading';
        state.error = null;
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(editPoint.fulfilled, (state, action) => {
        if (action?.payload?.error) {
          alert(action?.payload?.error.message);
          state.loadingStatus = 'serverError';
          state.error = action?.payload?.error;
        } else {
          const restPoints = state.points?.filter((point) => point.id !== action.payload.id);
          state.points = [action.payload, ...restPoints];
          state.loadingStatus = 'success';
          state.error = null;
        }
      })
      // Вызывается в случае ошибки
      .addCase(editPoint.rejected, (state, action) => {
        console.log('error');
        state.loadingStatus = 'failed';
        state.error = action.error;
      })

      // Добавление точки

      .addCase(addPoint.pending, (state) => {
        state.loadingStatus = 'isLoading';
        state.error = null;
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(addPoint.fulfilled, (state, action) => {
        if (!action?.payload) {
          alert('что-то пошло не так');
          state.loadingStatus = 'serverError';
          state.error = action?.payload?.error;
        } else {
          state.points = [action.payload, ...state.points];
          state.loadingStatus = 'success';
          state.error = null;
        }
      })
      // Вызывается в случае ошибки
      .addCase(addPoint.rejected, (state, action) => {
        console.log('error');
        state.loadingStatus = 'failed';
        state.error = action.error;
      })

      // Удаление точки

      .addCase(deletePoint.pending, (state) => {
        state.loadingStatus = 'isLoading';
        state.error = null;
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(deletePoint.fulfilled, (state, action) => {
        if (action?.payload?.error) {
          alert(action?.payload?.error.message);
          state.loadingStatus = 'serverError';
          state.error = action?.payload?.error;
        }
        state.points = state.points.filter((point) => point.id !== action.payload.id);
        state.loadingStatus = 'successDelete';
        state.error = null;
      })
      // Вызывается в случае ошибки
      .addCase(deletePoint.rejected, (state, action) => {
        console.log('error');
        state.loadingStatus = 'failed';
        state.error = action.error;
      })

      .addCase(copyPoint.pending, (state) => {
        state.loadingStatus = 'isLoading';
        state.error = null;
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(copyPoint.fulfilled, (state, action) => {
        if (action?.payload?.error) {
          alert(action?.payload?.error.message);
          state.loadingStatus = 'serverError';
          state.error = action?.payload?.error;
        }
        state.points = [action.payload, ...state.points];
        state.loadingStatus = 'success';
        state.error = null;
      })
      // Вызывается в случае ошибки
      .addCase(copyPoint.rejected, (state, action) => {
        console.log('error');
        state.loadingStatus = 'failed';
        state.error = action.error;
      });
  },
});

const currentPointInitialState: ICurrentPointStore = {
  loadingStatus: 'idle',
  error: null,
  point: null,
};

export const currentPointSlice = createSlice({
  name: 'currentPoint',
  initialState: currentPointInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPoint.pending, (state) => {
        state.loadingStatus = 'isLoading';
        state.error = null;
        state.point = null;
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(getPoint.fulfilled, (state, action) => {
        if (action?.payload?.error) {
          alert(action?.payload?.error.message);
          state.loadingStatus = 'serverError';
          state.error = action?.payload?.error;
        }
        if (action?.payload) {
          // Добавляем пользователя
          state.point = action.payload;
          state.loadingStatus = 'success';
          state.error = null;
        }
      })
      // Вызывается в случае ошибки
      .addCase(getPoint.rejected, (state, action) => {
        console.log('error');
        state.loadingStatus = 'failed';
        state.error = action.error;
        state.point = null;
      })

      .addCase(deletePointOperator.pending, (state) => {
        state.loadingStatus = 'isLoading';
        state.error = null;
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(deletePointOperator.fulfilled, (state, action) => {
        if (action?.payload?.error) {
          alert(action?.payload?.error.message);
          state.loadingStatus = 'serverError';
          state.error = action?.payload?.error;
        } else {
          state.loadingStatus = 'success';
          state.error = null;
          state.point = action.payload;
        }
      })
      // Вызывается в случае ошибки
      .addCase(deletePointOperator.rejected, (state, action) => {
        console.log('error');
        state.loadingStatus = 'failed';
        state.error = action.error;
      })

      .addCase(addPointOperator.pending, (state) => {
        state.loadingStatus = 'isLoading';
        state.error = null;
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(addPointOperator.fulfilled, (state, action) => {
        if (action?.payload?.error) {
          alert(action?.payload?.error.message);
          state.loadingStatus = 'serverError';
          state.error = action?.payload?.error;
        } else {
          state.loadingStatus = 'success';
          state.error = null;
          state.point = action.payload;
        }
      })
      // Вызывается в случае ошибки
      .addCase(addPointOperator.rejected, (state, action) => {
        console.log('error');
        state.loadingStatus = 'failed';
        state.error = action.error;
      });
  },
});

// export const { deletePointService } = pointSlice.actions;
