import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row, Table } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { getPoints } from '../../services/store/points';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../services/store/store';

import operatorsStyles from './Operators.module.css';
import OperatorForm from '../../components/OperatorForm/OperatorForm';
import { deleteUser, getUsers } from '../../services/store/operators';
import { IUser } from '../../services/types';
import notification from '../../services/notification';
import { ColumnsType } from 'antd/es/table';

const headerHeight = '260px';

const ServiceList: React.FC = () => {
  const [usersData, setUsersData] = useState<IUser[]>([]);
  const [editableOperator, setEditableOperator] = useState(null);
  const [openOperatorForm, setOpenOperatorForm] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState({ status: false, recordId: 0 });

  const users = useSelector((store: RootState) => store.users.users);
  const usersStatus = useSelector((store: RootState) => store.users.loadingStatus);

  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();

  const handleOpenOperatorForm = (record: any) => {
    if (!record) {
      setEditableOperator(null);
    } else {
      setEditableOperator(record);
      const name = record.attributes?.username.split(' ');
      form.setFieldsValue({
        firstName: name[0],
        lastName: name[1],
        phone: record.attributes.phone,
        email: record.attributes.email,
        id: record.id,
      });
    }
    setOpenOperatorForm(true);
  };

  const handleDeleteOperator = (id: number) => {
    setOpenDeleteModal({ status: true, recordId: id });
  };

  const handleOk = () => {
    dispatch(deleteUser(openDeleteModal.recordId));
    setOpenDeleteModal({ status: false, recordId: 0 });
  };

  const columns: ColumnsType<IUser> = [
    {
      title: 'Имя',
      dataIndex: ['attributes', 'firstName'],
      key: 'firstName',
      width: '15%',
      render: (_: any, record: any) => {
        const name = record.attributes.username.split(' ');
        return <span>{name[0]}</span>;
      },
    },
    {
      title: 'Фамилия',
      dataIndex: ['attributes', 'lastName'],
      key: 'lastName',
      width: '15%',
      render: (_: any, record: any) => {
        const name = record.attributes.username.split(' ');
        return <span>{name[1]}</span>;
      },
    },
    { title: 'Телефон', dataIndex: ['attributes', 'phone'], key: 'phone', width: '20%' },
    { title: 'Логин', dataIndex: ['attributes', 'email'], key: 'email', width: '25%' },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '15%',
      align: 'right',
      render: (_: any, record: any) => {
        return (
          <div className={operatorsStyles.actionButtons}>
            <Button type="link" size="small" onClick={() => handleOpenOperatorForm(record)}>
              <EditOutlined style={{ fontSize: '18px' }} />
            </Button>
            <Button type="link" size="small" onClick={() => handleDeleteOperator(record.id)}>
              <DeleteOutlined style={{ fontSize: '18px' }} />
            </Button>
          </div>
        );
      },
    },
  ];

  const handleSearch = (e: any) => {
    setUsersData(
      users.filter((item: any) =>
        item.attributes.username.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  useEffect(() => {
    setUsersData(users);
  }, [users]);

  useEffect(() => {
    dispatch(getPoints());
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    if (usersStatus === 'successUpdate') notification.success('Оператор успешно отредактирован');
    if (usersStatus === 'successDelete') notification.success('Оператор успешно удален');
    if (usersStatus === 'successAdd') notification.success('Оператор успешно добавлен');
    if (usersStatus === 'serverError')
      notification.error('Пользователь с таким емейлом уже существует');
  }, [usersStatus]);

  return (
    <div className="content-wrapper">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '12px',
        }}
      >
        <h1 className="page-title">Операторы точек</h1>
        <div style={{ display: 'flex' }}>
          <Button
            shape="round"
            icon={<PlusOutlined />}
            size="middle"
            onClick={() => handleOpenOperatorForm(undefined)}
            style={{ marginRight: '20px' }}
          >
            Добавить
          </Button>
          <Input
            size="middle"
            placeholder="Поиск оператора"
            prefix={<SearchOutlined />}
            className={operatorsStyles.searc}
            onChange={(e) => handleSearch(e)}
            style={{ width: '260px' }}
          />
        </div>
      </div>
      <div style={{ position: 'relative' }}>
        <Table
          size="small"
          pagination={{ pageSize: 15 }}
          columns={columns}
          dataSource={
            usersData && usersData.length
              ? usersData.map((user: any) => ({ ...user, key: user?.id }))
              : []
          }
          scroll={{
            x: `none`,
            y: `calc(100vh - ${headerHeight})`,
          }}
        />
      </div>

      <OperatorForm
        open={openOperatorForm}
        setOpen={setOpenOperatorForm}
        form={form}
        editableOperator={editableOperator}
      />
      <Modal
        title=""
        open={openDeleteModal.status}
        okText="Удалить"
        cancelText="Отменить"
        onOk={handleOk}
        onCancel={() => setOpenDeleteModal({ status: false, recordId: 0 })}
      >
        <p>Вы уверены, что хотите удалить оператора?</p>
      </Modal>
    </div>
  );
};

export default ServiceList;
