import { notification } from 'antd';
import { IconType } from 'antd/es/notification/interface';

const openNotification = (
  message: string,
  type: IconType,
  duration: number,
  title?: string
): void => {
  notification.open({
    message: title,
    description: message,
    placement: 'bottomLeft',
    type: type,
    duration: duration,
  });
};

const timeout = (type: IconType): number => {
  switch (type) {
    case 'error':
      return 15;
    case 'success':
      return 5;
    default:
      return 5;
  }
};

export default {
  info(message: string, title?: string) {
    const type = 'info';
    openNotification(message, type, timeout(type));
  },
  success(message: string) {
    const type = 'success';
    openNotification(message, type, timeout(type));
  },
  error(message: string) {
    const type = 'error';
    openNotification(message, type, timeout(type));
  },
};
