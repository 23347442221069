import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'antd';

import loginStyles from './Login.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../services/store/store';
import { loginUser } from '../../services/store/auth';

type FieldType = {
  identifier?: string;
  password?: string;
};

const Login: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const user = useSelector((store: RootState) => store.auth);
  const onFinish = (values: any) => {
    dispatch(loginUser(values));
  };
  const [form] = Form.useForm();

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  
  useEffect(() => {
    if (user.loadingStatus === 'serverError') {
      form.setFields([
        
        {
          name: 'password',
          errors: ['Неверный логин или пароль'],
        },
        {
          name: 'identifier',
          errors: ['Неверный логин или пароль'],
        },
      ]);
    }
    if (user.loadingStatus === 'success') {
      navigate('/');
    }
  }, [user]);

  return (
    <div className={loginStyles.loginFormWrapper}>
      <div className={loginStyles.loginForm}>
        <h1 className={loginStyles.title}>ВХОД В ЛИЧНЫЙ КАБИНЕТ</h1>
        <Form
          form={form}
          name="login"
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size="large"
          layout="vertical"
          className={loginStyles.loginFormInner}
        >
          <Form.Item<FieldType>
            label="Логин"
            name="identifier"
            rules={[{ required: true, message: 'Пожалуйста, введите свой логин' }]}
            className={loginStyles.loginField}
          >
            <Input />
          </Form.Item>

          <Form.Item<FieldType>
            label="Пароль"
            name="password"
            rules={[{ required: true, message: 'Пожалуйста, введите свой пароль' }]}
            className={loginStyles.loginField}
          >
            <Input.Password />
          </Form.Item>
          <p className={loginStyles.resetPassword}>
            Забыли пароль?{' '}
            <Button type="link" size="large">
              <Link to="/forgot-password" className={loginStyles.resetPasswordLink}>
                Восстановить
              </Link>
            </Button>
          </p>

          <Form.Item>
            <Button type="primary" htmlType="submit" className={loginStyles.loginButton}>
              Войти
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
