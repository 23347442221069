import { Alert, Button, Col, Form, Input, Row, Space } from "antd";
import TextArea from "antd/es/input/TextArea";
import { FC, useEffect } from "react";
import { IPoint, Status } from "../../services/types";
import FormMap from "../FormMap/FormMap";
import servicePointFormStyles from "./EditPointForm.module.css";
import WorkingHours from "../WorkingHours/WorkingHours";
import { ExclamationCircleOutlined } from "@ant-design/icons";

interface Props {
  editablePoint: IPoint | undefined;
  addressMap: string;
  setAddressMap: (address: string) => void;
  newCoords: number[];
  setNewCoords: (coords: number[]) => void;
  form: any;
  isValidationError: boolean;
  setValidationError: (isValidationError: boolean) => void;
  additionalModeration: boolean;
  setAdditionalModeration: (additionalModeration: boolean) => void;
}

type FieldType = {
  id: number;
  name?: string;
  address?: string;
  address1?: string;
  email: string;
  phone?: string;
  users?: string;
  services: string;
  description: string;
  serviceOperation?: string;
  workingHours?: any;
};

export const FirstTab: FC<Props> = ({
  editablePoint,
  addressMap,
  setAddressMap,
  newCoords,
  setNewCoords,
  form,
  isValidationError,
  setValidationError,
  additionalModeration,
  setAdditionalModeration,
}) => {
  const email = Form.useWatch("email", form);
  const phone = Form.useWatch("phone", form);
  const description = Form.useWatch("description", form);

  const isFieldDisabled =
    editablePoint?.attributes.status === Status.approved || editablePoint?.attributes.wasPublished;

  const handleClose = () => {
    setAdditionalModeration(false);
  };

  useEffect(() => {
    if (
      description !== editablePoint?.attributes.description ||
      phone !== editablePoint?.attributes.phone ||
      email !== editablePoint?.attributes.email ||
      (phone === "" && editablePoint?.attributes.phone === null)
    ) {
      setAdditionalModeration(true);
    } else {
      setAdditionalModeration(false);
    }
  }, [description, phone, email]);

  return (
    <div className={servicePointFormStyles.formInner}>
      {editablePoint?.attributes.status === Status.rejected && (
        <Alert
          style={{ marginBottom: "20px" }}
          message={
            editablePoint.attributes.rejectReason ??
            "Проверьте корректность заполненных данных для прохождения повторной модерации. Если поля заполнены корректно, свяжитесь с поддежкой для выяснения причины отказа."
          }
          type="error"
        />
      )}
      <Row>
        <Col span={12}>
          {editablePoint?.attributes.status === Status.approved && (
            <Alert
              message=" Редактирование полей email, телефон и описание требует повторной модерации точки обслуживания, точка будет временно удалена из каталога. Процедура модерации займет какое-то время, но мы постараемся провести ее максимально быстро."
              type="info"
              closable
              showIcon
              style={{ marginBottom: "20px" }}
            />
          )}
          <Form.Item name="id" style={{ display: "none" }}>
            <Input />
          </Form.Item>
          <Form.Item<FieldType>
            label="Название"
            name="name"
            rules={[
              { required: true, message: "Пожалуйста, введите название" },
              () => ({
                validator(rule, value) {
                  if (!value?.trim()?.length && value?.length) {
                    return Promise.reject(
                      new Error("Название точки не может состоять из пробелов")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Пожалуйста, введите название" disabled={isFieldDisabled} />
          </Form.Item>
          <Form.Item<FieldType>
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Пожалуйста, введите email" },
              { type: "email", message: "Пожалуйста, введите email в правильном формате" },
            ]}
          >
            <Input placeholder="Пожалуйста, введите e-mail" />
          </Form.Item>
          <Form.Item<FieldType>
            label="Телефон"
            name="phone"
            rules={[
              { required: true, message: "Пожалуйста, введите телефон" },
              () => ({
                validator(rule, value) {
                  if (!value?.trim()?.length && value?.length) {
                    return Promise.reject(new Error("Телефон не может состоять из пробелов"));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Пожалуйста, введите телефон" />
          </Form.Item>
          <Form.Item<FieldType> label="Время работы" name="workingHours">
            <WorkingHours
              form={form}
              isValidationError={isValidationError}
              setValidationError={setValidationError}
            />
          </Form.Item>
          <Form.Item<FieldType>
            label="Описание"
            name="description"
            rules={[
              { required: true, message: "Пожалуйста, введите описание" },
              () => ({
                validator(rule, value) {
                  if (!value?.trim()?.length && value?.length) {
                    return Promise.reject(
                      new Error("Описание не может состоять из пробелов")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <TextArea placeholder="Пожалуйста, введите описание" autoSize />
          </Form.Item>
          {editablePoint?.attributes.status === Status.approved && additionalModeration && (
            <Space style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: "20px",
                  left: "30px",
                  zIndex: 1,
                  color: "#ff4d4f",
                  fontSize: "20px",
                }}
              >
                <ExclamationCircleOutlined />
              </div>

              <Alert
                style={{ marginBottom: "20px", paddingLeft: "60px" }}
                description="Редактирование полей email, телефон и описания требует повторной модерации точки обслуживание, точка будет временно удалена из каталога до завершения модерации."
                type="error"
                action={
                  <Button size="small" danger onClick={handleClose}>
                    OK
                  </Button>
                }
              />
            </Space>
          )}
        </Col>
        <Col span={12}>
          {" "}
          <FormMap
            addressMap={addressMap}
            setAddressMap={setAddressMap}
            newCoords={newCoords}
            setNewCoords={setNewCoords}
            isAddressDisabled={isFieldDisabled}
          />
        </Col>
      </Row>
    </div>
  );
};
