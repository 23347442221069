import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'antd';

import resetPasswordStyles from './ResetPassword.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../services/store/store';
import { resetPassword } from '../../services/store/resetPassword';

type FieldType = {
  email: string;
};

const ForgotPassword: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const passwordResetStatus = useSelector((store: RootState) => store.resetPassword.loadingStatus);
  const onFinish = (values: { email: string }) => {
    dispatch(resetPassword(values.email));
  };
  const [form] = Form.useForm();

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    if (passwordResetStatus === 'failedResetPassword') {
      form.setFields([
        {
          name: 'email',
          errors: ['Данного пользователя не существует'],
        },
      ]);
    }
    if (passwordResetStatus === 'successResetPassword') {
      navigate('/forgot-password-info', { state: { forgotPassword: true } });
    }
  }, [passwordResetStatus]);

  return (
    <div className={resetPasswordStyles.formWrapper}>
      <div className={resetPasswordStyles.form}>
        <h1 className={resetPasswordStyles.title}>Восстановление пароля</h1>
        <Form
          form={form}
          name="login"
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size="large"
          layout="vertical"
          className={resetPasswordStyles.formInner}
        >
          <p className={resetPasswordStyles.subtitle}>
            Для получения инструкций по восстановлению пароля введите адрес вашей электронной почты
          </p>
          <Form.Item<FieldType>
            label=""
            name="email"
            rules={[{ required: true, message: 'Пожалуйста, введите свой email' }]}
            className={resetPasswordStyles.field}
          >
            <Input placeholder="Пожалуйста, введите свой email" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className={resetPasswordStyles.button}>
              Отправить код
            </Button>
          </Form.Item>
        </Form>
        <p className={resetPasswordStyles.linkToLogin}>
          Вспомнили пароль?{' '}
          <Button type="link" onClick={() => navigate('/login')}>
            Войти
          </Button>
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;
