import _ from 'lodash';
import StatisticStyles from './Statistics.module.css';
import { useEffect, useState } from 'react';
import { IOrder, IPointData, IStatistics } from '../../services/types';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../services/store/store';
import { Table, Tag } from 'antd';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/es/table';
import { getStatistics } from '../../services/store/statistics';
import InfoBlock from './InfoBlock';
import PieBlock from './PieBlock';
import BarChartBlock from './BarChartBlock';
require('dayjs/locale/ru');

const Statistics = () => {
  const [statisticsData, setStatisticsData] = useState<IPointData[]>();

  const statistics = useSelector((store: RootState) => store.statistics.statistics);

  const dispatch = useDispatch<AppDispatch>();

  const columns: ColumnsType<any> = [
    {
      title: 'Точка обслуживания',
      dataIndex: ['pointName'],
      key: 'name',
    },
    {
      title: 'Количество заказов',
      dataIndex: ['ordersCount'],
      key: 'ordersCount',
      width: '200px',
    },
    {
      title: 'Итого',
      dataIndex: ['amount'],
      key: 'amount',
      width: '200px',
    },
  ];

  useEffect(() => {
    if (statistics) {
      setStatisticsData(statistics.attributes.pointsData);
    }
  }, [statistics]);

  useEffect(() => {
    dispatch(getStatistics());
  }, []);

  return (
    <div className="content-wrapper statistics">
      <div>
        <h1 className="page-title">Статистика</h1>
      </div>
      <InfoBlock />
      <div>
        <div className={StatisticStyles.chartWrapper}>
          <BarChartBlock />
          <PieBlock />
        </div>
      </div>
      <div className={StatisticStyles.ordersTable}>
        <h3>Прибыль по точкам за последние 3 месяца</h3>
        <Table
          size="small"
          pagination={false}
          columns={columns}
          // scroll={{
          //   x: `600px`,
          //   y: `calc(100vh - ${headerHeight})`,
          // }}
          dataSource={statisticsData?.map((data: IPointData) => ({ ...data, key: data.pointId }))}
        />
      </div>
    </div>
  );
};

export default Statistics;
