import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from '../../routes';
import { IStatisticsStore } from '../types';

export const getStatistics = createAsyncThunk('statistics/getStatistics', () => {
  return fetch(`${BASE_URL}/dashboard?populate=*`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data.data;
    });
});

const initialState: IStatisticsStore = {
  loadingStatus: 'idle',
  error: null,
  statistics: {
    attributes: {
      amountByMonths: [{ monthNumber: 0, amount: 0 }],
      amountByPoints: [{ pointId: 0, name: '', amount: 0 }],
      pointsData: [
        {
          pointId: 0,
          pointName: '',
          ordersCount: '',
          amount: 0,
        },
      ],
      amountTotal: 0,
      amountTotalMonth: 0,
      countTotal: 0,
      countTotalMonth: 0,
    },
  },
};

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStatistics.pending, (state) => {
        state.loadingStatus = 'isLoading';
        state.error = null;
        state.statistics = initialState.statistics;
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(getStatistics.fulfilled, (state, action) => {
        if (action?.payload?.error) {
          alert(action?.payload?.error.message);
          state.loadingStatus = 'serverError';
          state.error = action?.payload?.error;
        }
        if (action?.payload) {
          let authToken = action.payload.jwt;
          if (authToken) {
            // Сохраняем токен в куку token
            localStorage.setItem('accessToken', authToken);
          }
          // Добавляем пользователя
          state.statistics = action.payload;
          state.loadingStatus = 'success';
          state.error = null;
        }
      })
      // Вызывается в случае ошибки
      .addCase(getStatistics.rejected, (state, action) => {
        console.log('error');
        state.loadingStatus = 'failed';
        state.error = action.error;
        state.statistics = initialState.statistics;
      });
  },
});
