import _ from 'lodash';
import StatisticStyles from './Statistics.module.css';
import Coin from '../../images/coin.svg';
import Package from '../../images/package.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/store/store';
import { useEffect, useState } from 'react';

const data = [
  {
    title: 'Всего заработано',
    amount: 0,
    index: 'money-total',
  },
  {
    title: 'Заработано за месяц',
    amount: 0,
    index: 'money-month',
  },
  {
    title: 'Общее количество заказов',
    amount: 0,
    index: 'orders-total',
  },
  {
    title: 'Количество заказов за месяц',
    amount: 0,
    index: 'order-month',
  },
];

const InfoBlock = () => {
  const [statisticsData, setStatisticsData] = useState(data);

  const statistics = useSelector((store: RootState) => store.statistics.statistics);

  useEffect(() => {
    if (statistics) {
      setStatisticsData([
        {
          title: 'Всего заработано',
          amount: statistics.attributes.amountTotal,
          index: 'money-total',
        },
        {
          title: 'Заработано за месяц',
          amount: statistics.attributes.amountTotalMonth,
          index: 'money-month',
        },
        {
          title: 'Общее количество заказов',
          amount: statistics.attributes.countTotal,
          index: 'orders-total',
        },
        {
          title: 'Количество заказов за месяц',
          amount: statistics.attributes.countTotalMonth,
          index: 'order-month',
        },
      ]);
    }
  }, [statistics]);

  return (
    <div className={StatisticStyles.list}>
      {statisticsData.map((item) => (
        <div key={item.index} className={StatisticStyles.listItemInner}>
          <div className={StatisticStyles.listItemIcon}>
            <img
              src={item.index === 'money-total' || item.index === 'money-month' ? Coin : Package}
              alt=""
            />
          </div>
          <div className={StatisticStyles.listItemInfo}>
            <p className={StatisticStyles.listItemTitle}>{item.title}</p>
            <p className={StatisticStyles.listItemAmount}>{item.amount}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default InfoBlock;
