import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import resetPasswordStyles from './ResetPassword.module.css';

const ForgotPasswordInfo: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className={resetPasswordStyles.formWrapper}>
      <h1 className={resetPasswordStyles.title}>Восстановление пароля</h1>
      <p className={resetPasswordStyles.subtitle}>
        На указанную почту отправлена инструкция по восстановлению пароля. <br />
        Если вы не обнаружили письмо, проверьте папку Спам.
      </p>
      <p className={resetPasswordStyles.linkToLogin}>
        Вспомнили пароль?{' '}
        <Button type="link" onClick={() => navigate('/login')}>
          Войти
        </Button>
      </p>
    </div>
  );
};

export default ForgotPasswordInfo;
