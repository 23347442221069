import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Table, Tag, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
  UpOutlined,
} from '@ant-design/icons';
import ServicePointForm from '../../components/AddPointForm/AddPointForm';
import { copyPoint, deletePoint, getPoints } from '../../services/store/points';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../services/store/store';
import { IPoint, Status } from '../../services/types';

import pointsStyles from './ServiceList.module.css';
import notification from '../../services/notification';
import EditServicePointForm from '../../components/EditPointForm/EditPointForm';
import dayjs from 'dayjs';

const headerHeight = '260px';

const ServiceList: React.FC = () => {
  const [isValidationError, setValidationError] = useState(false);
  const points = useSelector((store: RootState) => store.points.points);
  const pointStatus = useSelector((store: RootState) => store.points.loadingStatus);

  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();

  const [editablePoint, setEditablePoint] = useState<IPoint | undefined>(undefined);
  const [pointId, setPointId] = useState(0);
  const [openServicePointForm, setOpenServicePointForm] = useState(false);
  const [openEditServicePointForm, setOpenEditServicePointForm] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState({ status: false, recordId: 0 });
  const [openCopyModal, setOpenCopyModal] = useState({ status: false, recordId: 0 });
  const [pointsData, setPointsData] = useState(points);

  const handleDeletePoint = (id: number) => {
    setOpenDeleteModal({ status: true, recordId: id });
  };

  const handleCopyPoint = (record: IPoint) => {
    setOpenCopyModal({ status: true, recordId: record?.id });
  };

  const handleOk = () => {
    dispatch(deletePoint(openDeleteModal.recordId));
    setOpenDeleteModal({ status: false, recordId: 0 });
  };

  const handleCopy = () => {
    dispatch(copyPoint(openCopyModal.recordId));
    setOpenCopyModal({ status: false, recordId: 0 });
  };

  const columns: ColumnsType<IPoint> = [
    { title: 'Название', dataIndex: ['attributes', 'name'], key: 'name', width: '15%' },
    {
      title: 'Адрес',
      dataIndex: ['attributes', 'address'],
      key: 'address',
      ellipsis: true,
    },
    { title: 'Телефон', dataIndex: ['attributes', 'phone'], key: 'phone', width: 180 },
    { title: 'E-mail', dataIndex: ['attributes', 'email'], key: 'email', width: 180 },
    {
      title: 'Статус',
      dataIndex: '',
      key: 'x',
      width: 150,
      ellipsis: true,
      render: (_: any, record: IPoint) => {
        return (
          <>
            {record.attributes.status === Status.approved && (
              <Tag color="success">Опубликовано</Tag>
            )}

            {record.attributes.status === Status.rejected && (
              <Tooltip title={record.attributes.rejectReason ?? 'Проверьте корректность заполненных данных для прохождения повторной модерации. Если поля заполнены корректно, свяжитесь с поддежкой для выяснения причины отказа.'}>
                <Tag color="error">Отклонено</Tag>
              </Tooltip>
            )}
            {record.attributes.status === Status.pending && (
              <Tag color="processing">На рассмотрении</Tag>
            )}
          </>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: 120,
      align: 'right',
      render: (_: any, record: IPoint) => {
        return (
          <div className={pointsStyles.actionButtons}>
            <Button type="link" size="small" onClick={() => handleCopyPoint(record)}>
              <CopyOutlined style={{ fontSize: '18px' }} />
            </Button>
            <Button type="link" size="small" onClick={() => handleEditOpenServicePointForm(record)}>
              <EditOutlined style={{ fontSize: '18px' }} />
            </Button>
            <Button type="link" size="small" onClick={() => handleDeletePoint(record.id)}>
              <DeleteOutlined style={{ fontSize: '18px' }} />
            </Button>
          </div>
        );
      },
    },
  ];

  const handleEditOpenServicePointForm = (record: IPoint | undefined) => {
    if (record) setPointId(record?.id);

    if (record) {
      const data = record.attributes;
      const everyday = data.workingHours?.everyday;
      form.setFieldsValue({
        name: data.name,
        address: data.address,
        phone: data.phone,
        email: data.email,
        description: data.description,
        serviceOperation: data.serviceOperation,
        services: data.services?.data.map((item) => ({
          label: item.attributes.name,
          value: item.id,
        })),
        id: record.id,
        workingHours: {
          ...data.workingHours,
          mondayTime:
            data.workingHours?.monday && !everyday
              ? [
                  dayjs(data.workingHours?.mondayTime[0], 'HH:mm'),
                  dayjs(data.workingHours?.mondayTime[1], 'HH:mm'),
                ]
              : '',
          tuesdayTime:
            data.workingHours?.tuesday && !everyday
              ? [
                  dayjs(data.workingHours?.tuesdayTime[0], 'HH:mm'),
                  dayjs(data.workingHours?.tuesdayTime[1], 'HH:mm'),
                ]
              : '',
          wednesdayTime:
            data.workingHours?.wednesday && !everyday
              ? [
                  dayjs(data.workingHours?.wednesdayTime[0], 'HH:mm'),
                  dayjs(data.workingHours?.wednesdayTime[1], 'HH:mm'),
                ]
              : '',
          thursdayTime:
            data.workingHours?.thursday && !everyday
              ? [
                  dayjs(data.workingHours?.thursdayTime[0], 'HH:mm'),
                  dayjs(data.workingHours?.thursdayTime[1], 'HH:mm'),
                ]
              : '',
          fridayTime:
            data.workingHours?.friday && !everyday
              ? [
                  dayjs(data.workingHours?.fridayTime[0], 'HH:mm'),
                  dayjs(data.workingHours?.fridayTime[1], 'HH:mm'),
                ]
              : '',
          saturdayTime:
            data.workingHours?.saturday && !everyday
              ? [
                  dayjs(data.workingHours?.saturdayTime[0], 'HH:mm'),
                  dayjs(data.workingHours?.saturdayTime[1], 'HH:mm'),
                ]
              : '',
          sundayTime:
            data.workingHours?.sunday && !everyday
              ? [
                  dayjs(data.workingHours?.sundayTime[0], 'HH:mm'),
                  dayjs(data.workingHours?.sundayTime[1], 'HH:mm'),
                ]
              : '',
          everyday: data.workingHours?.everyday,
        },
      });
      setEditablePoint(record);
    } else {
      setEditablePoint(undefined);
    }
    setOpenEditServicePointForm(true);
  };

  const handleOpenServicePointForm = () => {
    setOpenServicePointForm(true);
  };

  const handleSearch = (e: any) => {
    setPointsData(
      points.filter((item) =>
        item.attributes.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  useEffect(() => {
    dispatch(getPoints());
  }, []);

  useEffect(() => {
    if (points) {
      setPointsData(points);
    }
  }, [points]);

  useEffect(() => {
    if (pointStatus === 'successDelete') notification.success('Точка успешно удалена');
  }, [pointStatus]);

  return (
    <div className="content-wrapper">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <h3 className="page-title">Точки обслуживания</h3>
        <div style={{ display: 'flex' }}>
          <Button
            shape="round"
            icon={<PlusOutlined />}
            size="middle"
            onClick={() => handleOpenServicePointForm()}
            style={{ marginRight: '20px' }}
          >
            Добавить
          </Button>
          <Input
            size="middle"
            placeholder="Поиск точки обслуживания"
            prefix={<SearchOutlined />}
            className={pointsStyles.profileSearch}
            onChange={(e) => handleSearch(e)}
            style={{ width: '260px' }}
          />
        </div>
      </div>

      {pointStatus === 'isLoading' ? (
        <div>
          <p>
            Данные загружаются <LoadingOutlined />
          </p>
        </div>
      ) : (
        <div style={{ position: 'relative' }}>
          <Table
            size="small"
            pagination={{ pageSize: 15 }}
            columns={columns}
            scroll={{
              x: '1100px',
              y: `calc(100vh - ${headerHeight})`,
            }}
            expandable={{
              columnWidth: '3%',
              expandedRowRender: (record) => {
                return (
                  <>
                    <p>
                      <strong>Часы работы:&nbsp;</strong>
                      {record.attributes.workingHours.everyday && ' Круглосуточно без выходных'}

                      {record.attributes.workingHours.monday && (
                        <p className={pointsStyles.workingHoursWrapper}>
                          <span className={pointsStyles.workingHours}>Понедельник:&nbsp;</span>
                          {record.attributes.workingHours.mondayTime[0]}-
                          {record.attributes.workingHours.mondayTime[1]}
                        </p>
                      )}

                      {record.attributes.workingHours.tuesday && (
                        <p className={pointsStyles.workingHoursWrapper}>
                          <span className={pointsStyles.workingHours}>Вторник:&nbsp;</span>
                          {record.attributes.workingHours.tuesdayTime[0]}-
                          {record.attributes.workingHours.tuesdayTime[1]}
                        </p>
                      )}
                      {record.attributes.workingHours.wednesday && (
                        <p className={pointsStyles.workingHoursWrapper}>
                          <span className={pointsStyles.workingHours}>Среда:&nbsp;</span>
                          {record.attributes.workingHours.wednesdayTime[0]}-
                          {record.attributes.workingHours.wednesdayTime[1]}
                        </p>
                      )}
                      {record.attributes.workingHours.thursday && (
                        <p className={pointsStyles.workingHoursWrapper}>
                          <span className={pointsStyles.workingHours}>Четверг:&nbsp;</span>
                          {record.attributes.workingHours.thursdayTime[0]}-
                          {record.attributes.workingHours.thursdayTime[1]}
                        </p>
                      )}
                      {record.attributes.workingHours.friday && (
                        <p className={pointsStyles.workingHoursWrapper}>
                          <span className={pointsStyles.workingHours}>Пятница:&nbsp;</span>
                          {record.attributes.workingHours.fridayTime[0]}-
                          {record.attributes.workingHours.fridayTime[1]}
                        </p>
                      )}
                      {record.attributes.workingHours.saturday && (
                        <p className={pointsStyles.workingHoursWrapper}>
                          <span className={pointsStyles.workingHours}>Суббота:&nbsp;</span>
                          {record.attributes.workingHours.saturdayTime[0]}-
                          {record.attributes.workingHours.saturdayTime[1]}
                        </p>
                      )}
                      {record.attributes.workingHours.sunday && (
                        <p className={pointsStyles.workingHoursWrapper}>
                          <span className={pointsStyles.workingHours}>Воскресенье:&nbsp;</span>
                          {record.attributes.workingHours.sundayTime[0]}-
                          {record.attributes.workingHours.sundayTime[1]}
                        </p>
                      )}
                    </p>
                  </>
                );
              },
              rowExpandable: (record) => record?.attributes.name !== 'Not Expandable',
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <UpOutlined style={{ color: '#2290d6' }} onClick={(e) => onExpand(record, e)} />
                ) : (
                  <DownOutlined style={{ color: '#2290d6' }} onClick={(e) => onExpand(record, e)} />
                ),
            }}
            dataSource={pointsData.map((point) => ({ ...point, key: point.id }))}
          />
        </div>
      )}

      <ServicePointForm
        open={openServicePointForm}
        setOpen={setOpenServicePointForm}
        form={form}
        isValidationError={isValidationError}
        setValidationError={setValidationError}
      />
      <EditServicePointForm
        editablePoint={editablePoint}
        setEditablePoint={setEditablePoint}
        open={openEditServicePointForm}
        setOpen={setOpenEditServicePointForm}
        form={form}
        pointId={pointId}
        isValidationError={isValidationError}
        setValidationError={setValidationError}
      />
      <Modal
        title=""
        open={openDeleteModal.status}
        okText="Удалить"
        cancelText="Отменить"
        onOk={handleOk}
        onCancel={() => setOpenDeleteModal({ status: false, recordId: 0 })}
      >
        <p>Вы уверены, что хотите удалить точку обслуживания?</p>
      </Modal>
      <Modal
        title=""
        open={openCopyModal.status}
        okText="Копировать"
        cancelText="Отменить"
        onOk={handleCopy}
        onCancel={() => setOpenCopyModal({ status: false, recordId: 0 })}
      >
        <p>
          Вы уверены, что хотите копировать точку обслуживания? Скопированная точка появится в
          начале списка.
        </p>
      </Modal>
    </div>
  );
};

export default ServiceList;
