import React from 'react';
import { Drawer, Tag } from 'antd';
import { IOrder } from '../../services/types';

interface OrderInfoProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  order: IOrder;
}

const OrderInfo: React.FC<OrderInfoProps> = ({ open, setOpen, order }) => {
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      title={order.attributes.pointId.data.attributes.name}
      style={{ position: 'relative' }}
      placement="right"
      width={700}
      onClose={onClose}
      open={open}
    >
      <div style={{position: 'absolute', top: '16px', right: '20px'}}>
        <>
          {order.attributes.status === 'payment_pending' && <Tag color="lime">Ожидание</Tag>}
          {order.attributes.status === 'canceled' && <Tag color="orange">Отменено</Tag>}
          {order.attributes.status === 'draft' && <Tag color="default">Черновик</Tag>}
          {order.attributes.status === 'succeeded' && <Tag color="processing">Оплачено</Tag>}
          {order.attributes.status === 'failed' && <Tag color="red">Ошибка</Tag>}
        </>
      </div>
      <>
        <p>
          <strong>{order.attributes.pointId.data.attributes.address}</strong>
        </p>
        <br />
        <p>
          <table className="table">
            <tr>
              <th>Наименование</th>
              <th>Цена за 1 ед.</th>
              <th>Количество</th>
              <th>Сумма</th>
            </tr>
            {order.attributes.cart.data.map((item) => (
              <tr>
                <td>{item.attributes.name}</td>
                <td>{item.attributes.price} ₽</td>
                <td>{item.attributes.quantity}</td>
                <td>{item.attributes.amount}</td>
              </tr>
            ))}
          </table>
        </p>
        <br />
        <br />
        <p>
          <strong>Итого: {order.attributes.amount} ₽</strong>
        </p>
      </>
    </Drawer>
  );
};

export default OrderInfo;
