import React, { useEffect, useState } from "react";
import { Button, Col, Drawer, Form, FormInstance, Input, Row } from "antd";
import servicePointFormStyles from "./AddPointForm.module.css";
import TextArea from "antd/es/input/TextArea";
import { addPoint } from "../../services/store/points";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../services/store/store";
import FormMap from "../FormMap/FormMap";
import WorkingHours from "../WorkingHours/WorkingHours";
import dayjs from "dayjs";
import { checkWorkingHours } from "../../services/utils";

interface Props {
  open: boolean;
  setOpen: (x: boolean) => void;
  form: FormInstance;
  isValidationError: boolean;
  setValidationError: (isValidationError: boolean) => void;
}

type FieldType = {
  id: number;
  name?: string;
  address?: string;
  address1?: string;
  email: string;
  phone?: string;
  users?: string;
  services: string;
  description: string;
  workingHours: {
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  };
};

const AddPointForm: React.FC<Props> = ({
  open,
  setOpen,
  form,
  isValidationError,
  setValidationError,
}) => {
  const [addressMap, setAddressMap] = useState("");
  const [newCoords, setNewCoords] = useState([59.938991, 30.315473]);

  const dispatch = useDispatch<AppDispatch>();
  const owner = useSelector((store: RootState) => store.auth.user.id);

  const onFinish = (values: any) => {
    const workingHours = values.workingHours;
    if (checkWorkingHours(values)) {
      setValidationError(false);

      dispatch(
        addPoint({
          name: values.name.trim(),
          address: values.address.trim(),
          email: values.email,
          phone: values.phone.trim(),
          description: values.description.trim(),
          users: values.users ? [...values.users, owner] : [owner],
          longitude: String(newCoords[1]) ?? "",
          latitude: String(newCoords[0]) ?? "",
          workingHours: {
            ...workingHours,
            mondayTime: workingHours.monday
              ? [
                  workingHours.mondayTime[0]?.format("HH:mm"),
                  workingHours.mondayTime[1]?.format("HH:mm"),
                ]
              : null,
            tuesdayTime: workingHours.tuesday
              ? [
                  workingHours.tuesdayTime[0]?.format("HH:mm"),
                  workingHours.tuesdayTime[1]?.format("HH:mm"),
                ]
              : null,
            wednesdayTime: workingHours.wednesday
              ? [
                  workingHours.wednesdayTime[0]?.format("HH:mm"),
                  workingHours.wednesdayTime[1]?.format("HH:mm"),
                ]
              : null,
            thursdayTime: workingHours.thursday
              ? [
                  workingHours.thursdayTime[0]?.format("HH:mm"),
                  workingHours.thursdayTime[1]?.format("HH:mm"),
                ]
              : null,
            fridayTime: workingHours.friday
              ? [
                  workingHours.fridayTime[0]?.format("HH:mm"),
                  workingHours.fridayTime[1]?.format("HH:mm"),
                ]
              : null,
            saturdayTime: workingHours.saturday
              ? [
                  workingHours.saturdayTime[0]?.format("HH:mm"),
                  workingHours.saturdayTime[1]?.format("HH:mm"),
                ]
              : null,
            sundayTime: workingHours.sunday
              ? [
                  workingHours.sundayTime[0]?.format("HH:mm"),
                  workingHours.sundayTime[1]?.format("HH:mm"),
                ]
              : null,
          },
        })
      );
      setOpen(false);
      form.resetFields();
    } else {
      setValidationError(true);
      form.setFields([
        {
          name: "workingHours",
          errors: ["Пожалуйста, заполните время работы"],
        },
      ]);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    if (!checkWorkingHours(errorInfo.values)) {
      setValidationError(true);
      form.setFields([
        {
          name: "workingHours",
          errors: ["Пожалуйста, заполните время работы"],
        },
      ]);
    }
    console.log("Failed:", errorInfo);
  };

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
    setAddressMap("");
  };

  useEffect(() => {
    form.setFieldsValue({
      address: addressMap,
      mondayTime: [dayjs("10:00", "HH:mm"), dayjs("22:00", "HH:mm")],
      tuesdayTime: [dayjs("10:00", "HH:mm"), dayjs("22:00", "HH:mm")],
      wednesdayTime: [dayjs("10:00", "HH:mm"), dayjs("22:00", "HH:mm")],
      thursdayTime: [dayjs("10:00", "HH:mm"), dayjs("22:00", "HH:mm")],
      fridayTime: [dayjs("10:00", "HH:mm"), dayjs("22:00", "HH:mm")],
      saturdayTime: [dayjs("10:00", "HH:mm"), dayjs("22:00", "HH:mm")],
      sundayTime: [dayjs("10:00", "HH:mm"), dayjs("22:00", "HH:mm")],
    });
  }, [addressMap]);

  return (
    <>
      <Drawer
        title={"Добавить точку обслуживания"}
        placement="right"
        onClose={handleClose}
        open={open}
        width="100%"
        className={servicePointFormStyles.drawer}
        destroyOnClose
      >
        <Form
          name="servicePoint"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          form={form}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size="large"
          layout="horizontal"
          className={servicePointFormStyles.form}
        >
          <div className={servicePointFormStyles.formInner}>
            <Row>
              <Col span={12}>
                {" "}
                <Form.Item name="id" style={{ display: "none" }}>
                  <Input />
                </Form.Item>
                <Form.Item<FieldType>
                  label="Название"
                  name="name"
                  rules={[
                    { required: true, message: "Пожалуйста, введите название" },
                    () => ({
                      validator(rule, value) {
                        if (!value?.trim()?.length && value?.length) {
                          return Promise.reject(
                            new Error("Название точки не может состоять из пробелов")
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Пожалуйста, введите название" />
                </Form.Item>
                <Form.Item<FieldType>
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Пожалуйста, введите email" },
                    { type: "email", message: "Пожалуйста, введите email в правильном формате" },
                  ]}
                >
                  <Input placeholder="Пожалуйста, введите e-mail" />
                </Form.Item>
                <Form.Item<FieldType>
                  label="Телефон"
                  name="phone"
                  rules={[
                    { required: true, message: "Пожалуйста, введите телефон" },
                    () => ({
                      validator(rule, value) {
                        if (!value?.trim()?.length && value?.length) {
                          return Promise.reject(new Error("Телефон не может состоять из пробелов"));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Пожалуйста, введите телефон" />
                </Form.Item>
                <Form.Item<FieldType> label="Время работы" name="workingHours">
                  <WorkingHours
                    form={form}
                    isValidationError={isValidationError}
                    setValidationError={setValidationError}
                  />
                </Form.Item>
                <Form.Item<FieldType>
                  label="Описание"
                  name="description"
                  rules={[
                    { required: true, message: "Пожалуйста, введите описание" },
                    () => ({
                      validator(rule, value) {
                        if (!value?.trim()?.length && value?.length) {
                          return Promise.reject(
                            new Error("Описание не может состоять из пробелов")
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <TextArea placeholder="Пожалуйста, введите описание" autoSize />
                </Form.Item>
              </Col>
              <Col span={12}>
                {" "}
                <FormMap
                  addressMap={addressMap}
                  setAddressMap={setAddressMap}
                  newCoords={newCoords}
                  setNewCoords={setNewCoords}
                  isAddressDisabled={false}
                />
              </Col>
            </Row>
          </div>

          <div className={servicePointFormStyles.buttons}>
            <Form.Item>
              <Button type="primary" htmlType="submit" className={servicePointFormStyles.addButton}>
                {"Добавить точку"}
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="default" htmlType="button" onClick={() => setOpen(false)}>
                Отмена
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default AddPointForm;
