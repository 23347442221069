import React from 'react';
import { Form, Input, Button, Drawer, FormInstance } from 'antd';

import { UserAddOutlined, CloseOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { addUser, editUser } from '../../services/store/operators';
import { AppDispatch } from '../../services/store/store';

interface FormValues {
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  servicePoint: string;
}

interface OperatorFormProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  form: FormInstance;
  editableOperator: any;
}

const OperatorForm: React.FC<OperatorFormProps> = ({
  open,
  setOpen,
  form,
  editableOperator,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const onFinish = (values: FormValues) => {
    if (editableOperator) {
      dispatch(editUser({ ...values, username: `${values.firstName} ${values.lastName}` }));
    } else {
      dispatch(addUser({ ...values, username: `${values.firstName} ${values.lastName}` }));
    }
    setOpen(false);
    form.resetFields();
  };

  const onCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <Drawer
      title={editableOperator ? "Редактировать оператора" : "Добавить оператора"}
      placement="right"
      width={400}
      closable={false}
      onClose={onCancel}
      open={open}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        requiredMark={true} // Показывать обязательный маркер (*) для полей
      >
        <Form.Item name="id" style={{ display: 'none' }}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Имя"
          name="firstName"
          rules={[{ required: true, message: 'Введите Имя' }]}
        >
          <Input placeholder="Имя" />
        </Form.Item>

        <Form.Item
          label="Фамилия"
          name="lastName"
          rules={[{ required: true, message: 'Введите Фамилию' }]}
        >
          <Input placeholder="Фамилия" />
        </Form.Item>
        {/* 
        <Form.Item
          label="Логин"
          name="username"
          rules={[{ required: true, message: 'Введите логин' }]}
        >
          <Input.Password placeholder="Логин" />
        </Form.Item> */}

        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, type: 'email', message: 'Введите корректный Email' }]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          label="Телефон"
          name="phone"
          rules={[{ required: true, message: 'Введите Телефон' }]}
        >
          <Input placeholder="Телефон" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" icon={<UserAddOutlined />} htmlType="submit">
            {editableOperator ? "Редактировать оператора" : "Добавить оператора"}
          </Button>
          <Button
            type="default"
            icon={<CloseOutlined />}
            onClick={onCancel}
            style={{ marginLeft: 8 }}
          >
            Отмена
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default OperatorForm;
