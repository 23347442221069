import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from '../../routes';
import { IServicesStore } from '../types';

export const getServices = createAsyncThunk('services/getServices', () => {
  return fetch(`${BASE_URL}/services?populate=*`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data.data;
    });
});

export const deleteService = createAsyncThunk('services/deleteServices', (id: number | string) => {
  return fetch(`${BASE_URL}/services/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data.data;
    });
});

export const editService = createAsyncThunk('services/editService', (values: any) => {
  return fetch(`${BASE_URL}/services/${values.id}?populate=*`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
    body: JSON.stringify({ data: values }),
  })
    .then((res) => res.json())
    .then((data) => {
      return data.data;
    });
});

export const addService = createAsyncThunk('services/addService', (values: any) => {
  return fetch(`${BASE_URL}/services`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
    body: JSON.stringify({ data: values }),
  })
    .then((res) => res.json())
    .then((data) => {
      return data.data;
    });
});

const initialState: IServicesStore = {
  loadingStatus: 'idle',
  error: null,
  services: [],
};

export const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getServices.pending, (state) => {
        state.loadingStatus = 'isLoading';
        state.error = null;
        state.services = [];
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(getServices.fulfilled, (state, action) => {
        if (action?.payload?.error) {
          alert(action?.payload?.error.message);
          state.loadingStatus = 'serverError';
          state.error = action?.payload?.error;
        }
        if (action?.payload) {
          let authToken = action.payload.jwt;
          if (authToken) {
            // Сохраняем токен в куку token
            localStorage.setItem('accessToken', authToken);
          }
          // Добавляем пользователя
          state.services = action.payload;
          state.loadingStatus = 'success';
          state.error = null;
        }
      })
      // Вызывается в случае ошибки
      .addCase(getServices.rejected, (state, action) => {
        console.log('error');
        state.loadingStatus = 'failed';
        state.error = action.error;
        state.services = [];
      })

      .addCase(deleteService.pending, (state) => {
        state.loadingStatus = 'isLoading';
        state.error = null;
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(deleteService.fulfilled, (state, action) => {
        if (action?.payload?.error) {
          alert(action?.payload?.error.message);
          state.loadingStatus = 'serverError';
          state.error = action?.payload?.error;
        }
        state.services = state.services?.filter((service) => service.id !== action.payload.id);
        state.loadingStatus = 'successDelete';
        state.error = null;
      })
      // Вызывается в случае ошибки
      .addCase(deleteService.rejected, (state, action) => {
        console.log('error');
        state.loadingStatus = 'failed';
        state.error = action.error;
      })

      .addCase(editService.pending, (state) => {
        state.loadingStatus = 'isLoading';
        state.error = null;
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(editService.fulfilled, (state, action) => {
        if (!action?.payload) {
          alert(action?.payload?.error.message);
          state.loadingStatus = 'serverError';
          state.error = action?.payload?.error;
        } else {
          const restServices = state.services?.filter(
            (service) => service.id !== action.payload.id
          );
          state.services = [action.payload, ...restServices];
          state.loadingStatus = 'success';
          state.error = null;
        }
      })
      // Вызывается в случае ошибки
      .addCase(editService.rejected, (state, action) => {
        console.log('error');
        state.loadingStatus = 'failed';
        state.error = action.error;
      })

      .addCase(addService.pending, (state) => {
        state.loadingStatus = 'isLoading';
        state.error = null;
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(addService.fulfilled, (state, action) => {
        if (action?.payload?.error) {
          alert(action?.payload?.error.message);
          state.loadingStatus = 'serverError';
          state.error = action?.payload?.error;
        }
        state.services = [action.payload, ...state.services];
        state.loadingStatus = 'success';
        state.error = null;
      })
      // Вызывается в случае ошибки
      .addCase(addService.rejected, (state, action) => {
        console.log('error');
        state.loadingStatus = 'failed';
        state.error = action.error;
      });
  },
});
