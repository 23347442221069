export const checkResponse = (res: Response) =>
  res.ok ? res.json() : res.json().then((err: any) => Promise.reject(err));

export const request = (url: string, options?: any) => {
  // принимает два аргумента: урл и объект опций, как и `fetch`
  return fetch(url, options)
    .then(checkResponse)
    .catch((err) => err);
};

export const checkWorkingHours = (values: any) => {
  const dayNames = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  if (values.workingHours.everyday) {
    return true;
  }

  for (const day of dayNames) {
    if (!!values.workingHours[day] === true) {
      const openCloseHours = values.workingHours[`${day}Time`];
      if (openCloseHours) return true;
    }
  }

  return false;
};
