import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from '../../routes';
import { request } from '../utils';

export const getUsers = createAsyncThunk('users/getUsers', () => {
  return fetch(`${BASE_URL}/sellers?populate=*`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data.data;
    });
});

export const addUser = createAsyncThunk('users/addUser', (values: any) => {
  return request(`${BASE_URL}/sellers`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
    body: JSON.stringify({ data: values }),
  }).then((data) => data.data);
});

export const editUser = createAsyncThunk('users/editUser', (values: any) => {
  return request(`${BASE_URL}/sellers/${values.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
    body: JSON.stringify({ data: values }),
  }).then((data) => data.data);
});

export const deleteUser = createAsyncThunk('users/deleteUser', (id: string | number) => {
  return request(`${BASE_URL}/sellers/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
  }).then((data) => data.data);
});

const initialState: any = {
  loadingStatus: 'idle',
  error: null,
  users: [],
};

export const usersSlice = createSlice({
  name: 'point',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.loadingStatus = 'isLoading';
        state.error = null;
        state.users = [];
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(getUsers.fulfilled, (state, action) => {
        if (action?.payload?.error) {
          alert(action?.payload?.error.message);
          state.loadingStatus = 'serverError';
          state.error = action?.payload?.error;
        }
        if (action?.payload) {
          // Добавляем пользователя
          state.users = action.payload;
          state.loadingStatus = 'success';
          state.error = null;
        }
      })
      // Вызывается в случае ошибки
      .addCase(getUsers.rejected, (state, action) => {
        console.log('error');
        state.loadingStatus = 'failed';
        state.error = action.error;
        state.users = [];
      })

      .addCase(addUser.pending, (state) => {
        state.loadingStatus = 'isLoading';
        state.error = null;
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(addUser.fulfilled, (state, action) => {
        if (!action.payload) {
          state.loadingStatus = 'serverError';
          state.error = action?.payload?.error;
        } else {
          state.users = [action.payload, ...state.users];
          state.loadingStatus = 'successAdd';
          state.error = null;
        }
      })
      // Вызывается в случае ошибки
      .addCase(addUser.rejected, (state, action) => {
        console.log('error');
        state.loadingStatus = 'failed';
        state.error = action.error;
        state.users = [];
      })
      .addCase(editUser.pending, (state) => {
        state.loadingStatus = 'isLoading';
        state.error = null;
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(editUser.fulfilled, (state, action) => {
        if (action?.payload?.error) {
          alert(action?.payload?.error.message);
          state.loadingStatus = 'serverError';
          state.error = action?.payload?.error;
        }
        const restOperators = state.users?.filter((user: any) => user.id !== action.payload.id);
        state.users = [action.payload, ...restOperators];
        state.loadingStatus = 'successUpdate';
        state.error = null;
      })
      // Вызывается в случае ошибки
      .addCase(editUser.rejected, (state, action) => {
        console.log('error');
        state.loadingStatus = 'failed';
        state.error = action.error;
        state.users = [];
      })

      .addCase(deleteUser.pending, (state) => {
        state.loadingStatus = 'isLoading';
        state.error = null;
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(deleteUser.fulfilled, (state, action) => {
        if (action?.payload?.error) {
          alert(action?.payload?.error.message);
          state.loadingStatus = 'serverError';
          state.error = action?.payload?.error;
        }
        state.users = state.users.filter((item: any) => item.id !== action.payload.id);
        state.loadingStatus = 'successDelete';
        state.error = null;
      })
      // Вызывается в случае ошибки
      .addCase(deleteUser.rejected, (state, action) => {
        console.log('error');
        state.loadingStatus = 'failed';
        state.error = action.error;
        state.users = [];
      });
  },
});
