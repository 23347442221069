import { DollarOutlined, EnvironmentOutlined, MailOutlined, PieChartOutlined, TeamOutlined } from '@ant-design/icons';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Layout, Menu, Modal } from 'antd';
import { Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../services/store/store';
import { sendFeedback } from '../../services/store/feedback';
import notification from '../../services/notification';
import sidebarStyles from './Sidebar.module.css';

const { Sider } = Layout;
const { Item } = Menu;
const { Title } = Typography;

const siderStyle: React.CSSProperties = {
  lineHeight: '120px',
  backgroundColor: '#FAFAFA',
};

interface Props {
  collapsed: boolean;
  setCollapsed: (i: boolean) => void;
}
const Sidebar: FC<Props> = ({ collapsed, setCollapsed }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const feedback = useSelector((store: RootState) => store.feedback.loadingStatus);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setCollapsed(true);
    setIsModalOpen(false);
  };

  const onFinish = (values: any) => {
    dispatch(sendFeedback(values));
    setIsModalOpen(false);
    setCollapsed(true);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    if (feedback === 'successSend') notification.success('Ваша заявка отправлена');
  }, [feedback]);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      theme="light"
      width="285"
      className="sidebar"
      trigger={null}
      style={siderStyle}
    >
      <Menu>
        <Item
          key="statistics"
          className={sidebarStyles.item}
          icon={<PieChartOutlined className={sidebarStyles.icon} />}
        >
          <Link to="/statistics">Статистика</Link>
        </Item>
        <Item
          key="service-point-list"
          className={sidebarStyles.item}
          icon={<EnvironmentOutlined className={sidebarStyles.icon} />}
        >
          <Link to="/">Точки обслуживания</Link>
        </Item>
        <Item
          key="operators-list"
          className={sidebarStyles.item}
          icon={<TeamOutlined className={sidebarStyles.icon} />}
        >
          <Link to="/operators">Список операторов</Link>
        </Item>
        <Item
          key="orders"
          className={sidebarStyles.item}
          icon={<DollarOutlined className={sidebarStyles.icon} />}
        >
          <Link to="/orders">Заказы</Link>
        </Item>
        <Item
          className={sidebarStyles.item}
          icon={<MailOutlined className={sidebarStyles.icon} />}
          onClick={showModal}
        >
          Связаться с нами
        </Item>
      </Menu>
      <Modal open={isModalOpen} closable footer={null} centered onCancel={handleCancel}>
        <Form
          name="basic"
          labelCol={{}}
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 600 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size="large"
        >
          <Title level={3} style={{ marginBottom: '20px' }}>
            Связаться с нами
          </Title>
          <Form.Item
            label=""
            name="message"
            rules={[{ required: true, message: 'Пожалуйста, введите описание' }]}
          >
            <TextArea placeholder="Ваше сообщение" autoSize={{ minRows: 6, maxRows: 8 }} />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 24 }}>
            <Button type="primary" htmlType="submit" size="large">
              Отправить
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Sider>
  );
};

export default Sidebar;
