import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Input, Row, Table, Tag } from 'antd';
import { addService, deleteService, editService } from '../../services/store/services';
import { AppDispatch, RootState } from '../../services/store/store';
import { IService } from '../../services/types';
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { getPoint } from '../../services/store/points';
import { getTags } from '../../services/store/tags';
import { EditableCell } from './EditableCell';

interface Props {
  pointId: number;
}

const headerHeight = '64px';
const contentHeaderHeight = '350px';

const ServicesTab: React.FC<Props> = ({ pointId }) => {
  const [editingKey, setEditingKey] = useState('');
  const [data, setData] = useState<IService[]>();
  const [isNewRecord, setNewRecord] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();

  const servicesStatus = useSelector((store: RootState) => store.services.loadingStatus);
  const services = useSelector((store: RootState) => store.services.services);
  const currentPoint = useSelector((store: RootState) => store.currentPoint.point);
  const [currentPointData, setCurrentPointData] = useState(currentPoint);

  const isEditing = (record: IService) => record.id === editingKey;

  const handleEditRecord = (record: IService) => {
    setNewRecord(false);
    edit(record);
  };

  const columns = [
    {
      title: 'Название услуги',
      dataIndex: ['attributes', 'name'],
      width: '25%',
      editable: true,
    },
    {
      title: 'Теги',
      dataIndex: 'serviceTags',
      width: '35%',
      editable: true,
      inputType: 'number',
      render: (tags: any[]) => (
        <span key={Date.now()}>
          {tags?.map((tag) => (
            <Tag style={{marginBottom: '7px'}} color="blue" key={tag.id}>
              {tag.attributes.name}
            </Tag>
          ))}
        </span>
      ),
    },
    {
      title: 'Цена',
      dataIndex: ['attributes', 'price'],
      width: '15%',
      editable: true,
    },
    {
      title: '',
      width: '10%',
      dataIndex: 'operation',
      render: (_: any, record: IService) => {
        const editable = isEditing(record);
        return editable ? (
          <span key={record.id}>
            <Button type="link" size="small" onClick={() => addNewService(record)}>
              <SaveOutlined style={{ fontSize: '18px' }} />
            </Button>

            <Button type="link" size="small" onClick={cancel}>
              <CloseCircleOutlined style={{ color: '#2290d6', fontSize: '18px' }} />
            </Button>
          </span>
        ) : (
          <span key={record.id}>
            <Button type="link" size="small" onClick={() => handleEditRecord(record)}>
              <EditOutlined style={{ fontSize: '18px' }} />
            </Button>
            <Button type="link" size="small" onClick={() => handleDeleteService(record.id)}>
              <DeleteOutlined style={{ fontSize: '18px' }} />
            </Button>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: IService) => ({
        record,
        inputType: col.dataIndex === 'serviceTags' ? 'multiselect' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const edit = (record: Partial<IService> & { id: any }) => {
    form.setFieldsValue({
      ...record,
      serviceTags: record.attributes?.service_tags?.data.map((tag) => ({
        label: tag.attributes.name,
        value: tag.id,
      })),
    });

    setEditingKey(record.id);
  };
  const handleDeleteService = (id: string | number) => {
    dispatch(deleteService(id));
    dispatch(getPoint(pointId));
  };

  const cancel = () => {
    if (isNewRecord) {
      setData(data?.filter((item, index) => index !== 0));
    }
    setEditingKey('');
  };

  const getServiceTags = () => {
    const tags = form.getFieldsValue(true).serviceTags;
    if (tags[0].label) {
      return tags.map((tag: any) => tag.value);
    } else {
      return tags;
    }
  };

  const addNewService = async (service: IService) => {
    try {
      await form.validateFields();
      const values = {
        name: form.getFieldsValue(true).attributes.name,
        price: form.getFieldsValue(true).attributes.price,
        service_tags: getServiceTags(),
        points: [currentPoint?.id],
      };
      if (isNewRecord) {
        dispatch(addService(values));
      } else {
        dispatch(editService({ ...values, id: service.id }));
      }
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleAdd = () => {
    const newData: any = {
      id: Date.now(),
      attributes: {
        name: ``,
        price: '',
      },
    };
    setNewRecord(true);
    setData(data?.length ? [newData, ...data] : [newData]);
    edit(newData);
  };
  const handleSearch = (e: any) => {
    setData(
      currentPointData?.attributes.services.data.filter((item) =>
        item.attributes.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  useEffect(() => {
    dispatch(getTags());
    dispatch(getPoint(pointId));
  }, []);

  useEffect(() => {
    if (currentPoint) {
      const flattenedData = currentPoint?.attributes.services.data.map((item: any) => ({
        ...item,
        serviceTags: _.get(item, 'attributes.service_tags.data', []),
      }));
      setData(flattenedData);
      setCurrentPointData(currentPoint);
    }
  }, [currentPoint, services]);

  useEffect(() => {
    dispatch(getPoint(pointId));
  }, [services]);

  return (
    <Form form={form} component={false}>
      {servicesStatus === 'isLoading' ? (
        'Данные загружаются'
      ) : (
        <div style={{ position: 'relative' }}>
          <Row>
            <Col span={12}>
              {' '}
              <Input
                size="large"
                placeholder="Поиск услуги"
                prefix={<SearchOutlined />}
                onChange={(e) => handleSearch(e)}
                style={{ marginBottom: '20px' }}
              />
            </Col>
            <Col span={11} offset={1}>
              <Button
                type="text"
                shape="round"
                icon={<PlusOutlined />}
                size="large"
                onClick={() => handleAdd()}
                disabled={!!editingKey}
              >
                Добавить услугу
              </Button>
            </Col>
          </Row>

          {data?.length ? (
            <Table
              size="small"
              scroll={{
                x: `none`,
                y: `calc(100vh - ${headerHeight} - ${contentHeaderHeight})`,
              }}
              pagination={{ pageSize: 15 }}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              dataSource={data}
              columns={mergedColumns}
              rowClassName="editable-row"
            />
          ) : (
            <p>У текущей точки нет услуг</p>
          )}
        </div>
      )}
    </Form>
  );
};

export default ServicesTab;
