import { Spin } from 'antd';
import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { RootState } from '../../services/store/store';

interface Props {
  onlyUnAuth: boolean;
  element: ReactNode;
}

export const ProtectedRouteElement: FC<Props> = ({ onlyUnAuth, element }) => {
  const location = useLocation();
  const user = useSelector((store: RootState) => store.auth);

  const isAuthChecked = user.user.id;
  const { from } = location.state || { from: { pathname: '/' } }; //login

  if (user.loadingStatus === 'patchingIsLoading' || user.loadingStatus === 'gettingDataIsLoading')
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '100px',
        }}
      >
        <Spin />
      </div>
    );

  if (!onlyUnAuth && !isAuthChecked) {
    return (
      <Navigate to="/login" state={{ from: location }} /> //profile
    );
  }
  if (onlyUnAuth && isAuthChecked) {
    return <Navigate to={from} />;
  }

  return <>{element}</>;
};
