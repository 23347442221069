import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import Form from 'antd/es/form';
import { FC, useEffect, useRef, useState } from 'react';
import { AutoComplete } from 'antd';
import { API_KEY } from '../../routes';

interface Props {
  addressMap: string;
  setAddressMap: (address: string) => void;
  newCoords: number[];
  setNewCoords: (address: number[]) => void;
  isAddressDisabled: boolean | undefined;
}

type FieldType = {
  id: number;
  name?: string;
  address?: string;
  address1?: string;
  email: string;
  phone?: string;
  users?: string;
  services: string;
  description: string;
  serviceOperation: string;
};

interface IOption {
  Point: {
    pos: string;
  };
  boundedBy: {
    Envelope: {
      lowerCorner: string;
      upperCorner: string;
    };
  };
  description: string;
  metaDataProperty: {
    GeocoderMetaData: {};
  };
  uri: string;
}

const FormMap: FC<Props> = ({
  addressMap,
  setAddressMap,
  newCoords,
  setNewCoords,
  isAddressDisabled,
}) => {
  const [options, setOptions] = useState<IOption[]>([]);
  const ref = useRef<any>();
  const ref2 = useRef<any>();
  const ymaps = useRef<any>(null);

  useEffect(() => {
    (async () => {
      try {
        if (addressMap && addressMap.trim().length) {
          const res = await fetch(
            `https://geocode-maps.yandex.ru/1.x/?apikey=${API_KEY}&format=json&geocode=${addressMap}&bbox=60.755766,45.884003~54.223908,27.907662`
          );
          const data = await res.json();
          const collection = data.response.GeoObjectCollection.featureMember.map(
            (item: any) => item.GeoObject
          );
          if (collection) {
            const coords = collection[0].Point.pos
              .split(' ')
              .map((item: any) => Number(item))
              .reverse();
            if (coords) {
              setNewCoords(coords);
            }
          }

          setOptions(() => collection);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [addressMap]);

  return (
    <Form.Item<FieldType>
      label="Адрес"
      name="address"
      style={{ marginBottom: '25px' }}
      rules={[
        { required: true, message: 'Пожалуйста, введите адрес' },
        () => ({
          validator(rule, value) {
            console.log(value, 'value')
            if (!value.trim().length && value.length) {
              return Promise.reject(
                new Error("Адрес не может состоять из пробелов")
              );
            }
            return Promise.resolve();
          },
        }),
      ]}
    >
      <YMaps
        query={{
          load: 'package.full',
          apikey: API_KEY,
        }}
      >
        <AutoComplete
          defaultActiveFirstOption={false}
          disabled={isAddressDisabled}
          value={addressMap}
          style={{ marginBottom: '25px' }}
          onChange={(newValue) => {
            const obg = options.find((item: any) =>
              newValue.includes(item.metaDataProperty.GeocoderMetaData.Address.formatted)
            );
            const coords = obg?.Point.pos
              .split(' ')
              .map((item: any) => Number(item))
              .reverse();
            if (coords) {
              setNewCoords(coords);
            }
            setAddressMap(newValue);
          }}
          onSelect={(value, option) => setAddressMap(option.label)}
          placeholder="Пожалуйста, введите адрес"
          options={options.map((item: any, i) => ({
            value: item.metaDataProperty.GeocoderMetaData.Address.formatted,
            label: item.metaDataProperty.GeocoderMetaData.Address.formatted,
          }))}
        />
        <Map
          instanceRef={ref2}
          state={{
            center: newCoords,
            zoom: 15,
          }}
          width="100%"
          height="700px"
          onLoad={(e) => {
            ymaps.current = e;
            // const points = [
            //   [48.024402067130715, 39.85466330972504],
            //   [46.780699672601415, 39.807971415195674],
            // ];
            // const bounds = e.util.bounds.fromPoints(points);
            // ref2.current?.setBounds(bounds, { checkZoomRange: true });

            // e.geocode(newCoords).then((res: any) => {});
          }}
          modules={['control.ZoomControl']}
          onClick={(event: any) => {
            if (isAddressDisabled) return;
            const coords = event.get('coords');
            setNewCoords(coords);
            ymaps.current.geocode(coords).then((res: any) => {
              const firstGeoObject = res.geoObjects.get(0);
              const newAddress = [
                firstGeoObject.getLocalities().length
                  ? firstGeoObject.getLocalities()
                  : firstGeoObject.getAdministrativeAreas(),
                firstGeoObject.getThoroughfare() || firstGeoObject.getPremise(),
                firstGeoObject.getPremiseNumber(),
              ]
                .filter(Boolean)
                .join(', ');
              ref.current.getMap().hint.open(coords, newAddress);
              setAddressMap(newAddress);
            });
          }}
        >
          <Placemark
            instanceRef={ref}
            onDragEnd={(event: any) => {
              if (isAddressDisabled) return;
              const coords = ref.current.geometry._coordinates;
              setNewCoords(coords);
              ymaps.current.geocode(coords).then((res: any) => {
                const firstGeoObject = res.geoObjects.get(0);
                const newAddress = [
                  firstGeoObject.getLocalities().length
                    ? firstGeoObject.getLocalities()
                    : firstGeoObject.getAdministrativeAreas(),
                  firstGeoObject.getThoroughfare() || firstGeoObject.getPremise(),
                  firstGeoObject.getPremiseNumber(),
                ]
                  .filter(Boolean)
                  .join(', ');
                ref.current.getMap().hint.open(coords, newAddress);
                setAddressMap(newAddress);
              });
            }}
            geometry={newCoords}
            options={{
              iconImageSize: [30, 30],
              draggable: true,
              preset: 'islands#greenIcon',
              hideIconOnBalloonOpen: false,
              openEmptyHint: true,
            }}
            properties={{
              iconContent: '+',
              hintContent: addressMap,
            }}
          />
        </Map>
      </YMaps>
    </Form.Item>
  );
};

export default FormMap;
