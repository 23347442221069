import _ from 'lodash';
import { Bar, BarChart, ResponsiveContainer, XAxis, Tooltip, Cell } from 'recharts';

import StatisticStyles from './Statistics.module.css';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/store/store';

const BarChartBlock = () => {
  const [dataSet, setDataSet] = useState<any>([]);
  const [amountPerMonth, setAmountPerMonth] = useState({ monthNumber: 0, amount: 0, month: '' });
  const [activeIndex, setActiveIndex] = useState(0);
  const statistics = useSelector((store: RootState) => store.statistics.statistics);

  const showAmount = (e: any) => {
    setAmountPerMonth({
      ...e,
      month: dayjs()
        .locale('ru')
        .month(e.monthNumber - 1)
        .format('MMMM'),
    });
  };

  const handleClick = (data: any, index: any) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    if (statistics.attributes.amountByMonths && statistics.attributes.amountByMonths.length) {
      const length = statistics.attributes.amountByMonths.length;
      const defaultMonth = statistics.attributes.amountByMonths[length - 1];
      setActiveIndex(length - 1);
      setAmountPerMonth({
        ...defaultMonth,
        month: dayjs()
          .locale('ru')
          .month(defaultMonth.monthNumber - 1)
          .format('MMMM'),
      });
      const data = [];
      for (let day = 1; day <= 12; day++) {
        const newItem = statistics.attributes.amountByMonths.find((x) => x.monthNumber === day) || {
          monthNumber: day,
          value: 0,
        };
        data.push(newItem);
      }
      setDataSet(
        data.map((item) => ({
          ...item,
          month: dayjs()
            .locale('ru')
            .month(item.monthNumber - 1)
            .format('MMMM'),
        }))
      );
    }
  }, [statistics]);

  return (
    <div className={StatisticStyles.barChartWrapper}>
      <h4 className={StatisticStyles.pieTitle} style={{ marginBottom: '50px' }}>
        Заработано{' '}
        {amountPerMonth.amount && (
          <>
            за {amountPerMonth.month} - {amountPerMonth.amount} ₽
          </>
        )}
      </h4>
      <ResponsiveContainer width="100%" height={160}>
        <BarChart width={150} height={60} data={dataSet}>
          <XAxis
            dataKey="month"
            label={{ fontSize: 8, color: 'red' }}
            axisLine={false}
            tickLine={false}
          />

          <Tooltip
            formatter={(value, name) => [`${value} ₽`, null]}
            labelFormatter={(value) => ''}
            offset={30}
            cursor={false}
          />

          <Bar
            data={dataSet}
            dataKey="amount"
            fill="#ECECEC"
            onMouseOver={(e) => showAmount(e)}
            activeBar={{ fill: '#00A3E8', cursor: 'pointer' }}
            onClick={handleClick}
          >
            {dataSet.map((entry: any, index: any) => (
              <Cell
                cursor="pointer"
                fill={index === activeIndex ? '#00A3E8' : '#ECECEC'}
                key={`cell-${index}`}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartBlock;
