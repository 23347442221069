import { Checkbox, Form, TimePicker, Typography } from 'antd';
import { useState, useEffect, FC } from 'react';

import workingHoursStyles from './WorkingHours.module.css';

const { Text } = Typography;

type DayType = {
  dayValue: string;
  dayLabel: string;
  timeValue: string;
  disabled?: boolean;
};

const data: DayType[] = [
  {
    dayValue: 'everyday',
    dayLabel: '24/7',
    timeValue: 'everydayTime',
  },
  {
    dayValue: 'monday',
    dayLabel: 'Понедельник',
    timeValue: 'mondayTime',
    disabled: false,
  },
  {
    dayValue: 'tuesday',
    dayLabel: 'Вторник',
    timeValue: 'tuesdayTime',
    disabled: false,
  },
  {
    dayValue: 'wednesday',
    dayLabel: 'Среда',
    timeValue: 'wednesdayTime',
    disabled: false,
  },
  {
    dayValue: 'thursday',
    dayLabel: 'Четверг',
    timeValue: 'thursdayTime',
    disabled: false,
  },
  {
    dayValue: 'friday',
    dayLabel: 'Пятница',
    timeValue: 'fridayTime',
    disabled: false,
  },
  {
    dayValue: 'saturday',
    dayLabel: 'Суббота',
    timeValue: 'saturdayTime',
    disabled: false,
  },
  {
    dayValue: 'sunday',
    dayLabel: 'Воскресенье',
    timeValue: 'sundayTime',
    disabled: false,
  },
];

interface Props {
  form: any;
  isValidationError: boolean;
  setValidationError: (isValidationError: boolean) => void;
}

const WorkingHours: FC<Props> = ({ form, isValidationError, setValidationError }) => {
  const [isFieldsDisabled, setFieldsDisabled] = useState(false);
  const [fields, setFields] = useState<any>();
  const [formData, setFormData] = useState<any>();
  const everyday = Form.useWatch(['workingHours', 'everyday'], form);
  const workingHours = Form.useWatch('workingHours', form);
  const format = 'HH:mm';

  const handleChange = (e: any) => {
    const target = e.target;
    const value = target.checked;
    const name = target.name;
    setFields([
      ...fields.map((item: any) => {
        if (item.dayValue === name) {
          return { ...item, disabled: value };
        }
        return item;
      }),
    ]);
  };

  const checkValue = (day: DayType) => {
    if (formData?.name) {
      Object.keys(formData).forEach((key) => {
        if (key === day.dayValue && formData[key]) {
          return true;
        }
      });
    } else {
      return isFieldsDisabled || !day.disabled;
    }
  };

  useEffect(() => {
    if (everyday) {
      setFieldsDisabled(true);
    } else {
      setFieldsDisabled(false);
    }
  }, [everyday]);

  useEffect(() => {
    setValidationError(false);
    form.setFields([
      {
        name: 'workingHours',
        errors: null,
      },
    ]);
  }, [workingHours]);

  useEffect(() => {
    setFormData(form.getFieldsValue(true));
    setFields(data);
  }, []);

  return (
    <div className={isValidationError ? 'input-error' : ''}>
      <div className={workingHoursStyles.form}>
        <div>
          <div className={workingHoursStyles.row}>
            <h3 className={workingHoursStyles.left}>День недели</h3>
            <h3 className={workingHoursStyles.right}>Время работы</h3>
          </div>
        </div>
        {fields &&
          fields.map((day: any, index: number) => (
            <div className={workingHoursStyles.row} key={index}>
              <div className={workingHoursStyles.left}>
                <Text>{day.dayLabel}</Text>
                <Form.Item
                  name={['workingHours', day.dayValue]}
                  valuePropName="checked"
                  className={workingHoursStyles.days}
                >
                  <Checkbox
                    disabled={isFieldsDisabled && day.dayValue !== 'everyday'}
                    name={day.dayValue}
                    onChange={(e) => handleChange(e)}
                  ></Checkbox>
                </Form.Item>
              </div>
              <div className={workingHoursStyles.right}>
                {day.dayValue !== 'everyday' && (
                  <Form.Item name={['workingHours', day.timeValue]}>
                    <TimePicker.RangePicker
                      format={format}
                      minuteStep={15}
                      placeholder={['Начало', 'Конец']}
                      // suffixIcon={<></>}
                      disabled={checkValue(day)}
                      changeOnBlur
                    />
                  </Form.Item>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default WorkingHours;
