import React, { useEffect, useState } from 'react';
import { Button, Drawer, Form, FormInstance, Tabs, TabsProps } from 'antd';
import { IPoint, Status } from '../../services/types';
import { editPoint } from '../../services/store/points';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../services/store/store';
import { FirstTab } from './FirstTab';
import ServicesTab from './ServicesTab';
import OperatorsTab from './OperatorsTab';
import { checkWorkingHours } from '../../services/utils';

import servicePointFormStyles from './EditPointForm.module.css';

interface Props {
  open: boolean;
  setOpen: (x: boolean) => void;
  editablePoint: IPoint | undefined;
  setEditablePoint: (data: IPoint | undefined) => void;
  form: FormInstance;
  pointId: number;
  isValidationError: boolean;
  setValidationError: (isValidationError: boolean) => void;
}

const EditPointForm: React.FC<Props> = ({
  open,
  setOpen,
  editablePoint,
  form,
  pointId,
  isValidationError,
  setValidationError,
}) => {
  const [addressMap, setAddressMap] = useState('');
  const [newCoords, setNewCoords] = useState([59.938991, 30.315473]);
  const [additionalModeration, setAdditionalModeration] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const onFinish = (values: any) => {
    if (checkWorkingHours(values)) {
      const workingHours = values.workingHours;
      setValidationError(false);
      dispatch(
        editPoint({
          id: values.id,
          name: values.name.trim(),
          address: addressMap.trim(),
          email: values.email,
          phone: values.phone.trim() ?? '',
          description: values.description.trim(),
          longitude: String(newCoords[1]) ?? '',
          latitude: String(newCoords[0]) ?? '',
          workingHours: {
            ...workingHours,
            mondayTime: workingHours.monday
              ? [
                  workingHours.mondayTime[0]?.format('HH:mm'),
                  workingHours.mondayTime[1]?.format('HH:mm'),
                ]
              : null,
            tuesdayTime: workingHours.tuesday
              ? [
                  workingHours.tuesdayTime[0]?.format('HH:mm'),
                  workingHours.tuesdayTime[1]?.format('HH:mm'),
                ]
              : null,
            wednesdayTime: workingHours.wednesday
              ? [
                  workingHours.wednesdayTime[0]?.format('HH:mm'),
                  workingHours.wednesdayTime[1]?.format('HH:mm'),
                ]
              : null,
            thursdayTime: workingHours.thursday
              ? [
                  workingHours.thursdayTime[0]?.format('HH:mm'),
                  workingHours.thursdayTime[1]?.format('HH:mm'),
                ]
              : null,
            fridayTime: workingHours.friday
              ? [
                  workingHours.fridayTime[0]?.format('HH:mm'),
                  workingHours.fridayTime[1]?.format('HH:mm'),
                ]
              : null,
            saturdayTime: workingHours.saturday
              ? [
                  workingHours.saturdayTime[0]?.format('HH:mm'),
                  workingHours.saturdayTime[1]?.format('HH:mm'),
                ]
              : null,
            sundayTime: workingHours.sunday
              ? [
                  workingHours.sundayTime[0]?.format('HH:mm'),
                  workingHours.sundayTime[1]?.format('HH:mm'),
                ]
              : null,
          },
        })
      );
      setOpen(false);
      form.resetFields();
    } else {
      setValidationError(true);
      form.setFields([
        {
          name: 'workingHours',
          errors: ['Пожалуйста, заполните время работы'],
        },
      ]);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    if (!checkWorkingHours(errorInfo.values)) {
      setValidationError(true);
      form.setFields([
        {
          name: 'workingHours',
          errors: ['Пожалуйста, заполните время работы'],
        },
      ]);
    }
    console.log('Failed:', errorInfo);
  };
  const handleClose = () => {
    setOpen(false);
    form.resetFields();
    setAddressMap('');
  };

  useEffect(() => {
    if (editablePoint) {
      setAddressMap(editablePoint?.attributes.address);
    }
  }, [editablePoint]);

  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Основное',
      children: (
        <FirstTab
          editablePoint={editablePoint}
          addressMap={addressMap}
          setAddressMap={setAddressMap}
          newCoords={newCoords}
          setNewCoords={setNewCoords}
          form={form}
          isValidationError={isValidationError}
          setValidationError={setValidationError}
          additionalModeration={additionalModeration}
          setAdditionalModeration={setAdditionalModeration}
        />
      ),
    },
    {
      key: '2',
      label: 'Услуги',
      children: <ServicesTab pointId={pointId} />,
    },
    {
      key: '3',
      label: 'Операторы',
      children: editablePoint && <OperatorsTab pointId={pointId} />,
    },
  ];

  return (
    <>
      <Drawer
        title="Редактировать точку обслуживания"
        placement="right"
        onClose={handleClose}
        open={open}
        width="100%"
        className={servicePointFormStyles.drawer}
        destroyOnClose
      >
        <Form
          name="servicePoint"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          form={form}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size="large"
          layout="horizontal"
          className={servicePointFormStyles.form}
        >
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />

          <div className={servicePointFormStyles.buttons}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={servicePointFormStyles.addButton}
                disabled={
                  editablePoint?.attributes.status === Status.approved && additionalModeration
                }
              >
                {editablePoint ? 'Редактировать точку' : 'Добавить точку'}
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="default" htmlType="button" onClick={() => setOpen(false)}>
                Отмена
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default EditPointForm;
