import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from '../../routes';
import { IResetPasswordStore } from '../types';
import { request } from '../utils';

export const resetPassword = createAsyncThunk('resetPassword', (email: string) => {
  const payload = {
    email: email,
  };
  return request(`${BASE_URL}/auth/forgot-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(payload),
  }).then((data) => data);
});

export const setNewPassword = createAsyncThunk(
  'setNewPassword',
  (values: { password: string; passwordConfirmation: string; code: string }) => {
    return request(`${BASE_URL}/auth/reset-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        password: values.password,
        passwordConfirmation: values.passwordConfirmation,
        code: values.code,
      }),
    }).then((data) => data);
  }
);

const initialStateResetPassword: IResetPasswordStore = {
  loadingStatus: 'idle',
  error: null,
};

export const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState: initialStateResetPassword,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Вызывается прямо перед выполнением запроса
      .addCase(resetPassword.pending, (state) => {
        state.loadingStatus = 'loading';
        state.error = null;
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(resetPassword.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.error = action.payload.error.message;
          state.loadingStatus = 'failedResetPassword';
        } else {
          state.error = null;
          state.loadingStatus = 'successResetPassword';
        }
      })
      // Вызывается в случае ошибки
      .addCase(resetPassword.rejected, (state, action) => {
        state.loadingStatus = 'failed';
        // https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
        state.error = action.error;
      })

      .addCase(setNewPassword.pending, (state) => {
        state.loadingStatus = 'loading';
        state.error = null;
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(setNewPassword.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.error = action.payload.error.message;
          state.loadingStatus = 'failedSetNewPassword';
        } else {
          state.error = null;
          state.loadingStatus = 'successSetNewPassword';
        }
      })
      // Вызывается в случае ошибки
      .addCase(setNewPassword.rejected, (state, action) => {
        state.loadingStatus = 'failed';
        // https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
        state.error = action.error;
      });
  },
});
