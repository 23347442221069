import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from '../../routes';
import { IOrdersStore } from '../types';

export const getOrders = createAsyncThunk('orders/getOrders', () => {
  return fetch(`${BASE_URL}/orders?populate=*&sort=createdAt:desc`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data.data;
    });
});

export const getFilteredOrders = createAsyncThunk('orders/getFilteredOrders', (filters: any) => {
  let url = '';
  if (!filters.dateFrom) {
    url = `${BASE_URL}/orders?populate=*&sort=createdAt:desc&filters[pointId][id]=${filters.pointId}`;
  } else if (!filters.pointId) {
    url = `${BASE_URL}/orders?populate=*&sort=createdAt:desc&filters[createdAt][$gte]=${filters.dateFrom}&filters[createdAt][$lte]=${filters.dateTo}`;
  } else {
    url = `${BASE_URL}/orders?populate=*&sort=createdAt:desc&filters[createdAt][$gte]=${filters.dateFrom}&filters[createdAt][$lte]=${filters.dateTo}&&filters[pointId][id]=${filters.pointId}`;
  }
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data.data;
    });
});

const initialState: IOrdersStore = {
  loadingStatus: 'idle',
  error: null,
  orders: [],
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state) => {
        state.loadingStatus = 'isLoading';
        state.error = null;
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(getOrders.fulfilled, (state, action) => {
        if (action?.payload?.error) {
          alert(action?.payload?.error.message);
          state.loadingStatus = 'serverError';
          state.error = action?.payload?.error;
        }
        if (action?.payload) {
          // Добавляем пользователя
          state.orders = action.payload;
          state.loadingStatus = 'success';
          state.error = null;
        }
      })
      // Вызывается в случае ошибки
      .addCase(getOrders.rejected, (state, action) => {
        console.log('error');
        state.loadingStatus = 'failed';
        state.error = action.error;
        state.orders = [];
      })

      .addCase(getFilteredOrders.pending, (state) => {
        state.loadingStatus = 'isLoading';
        state.error = null;
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(getFilteredOrders.fulfilled, (state, action) => {
        if (action?.payload?.error) {
          alert(action?.payload?.error.message);
          state.loadingStatus = 'serverError';
          state.error = action?.payload?.error;
        }
        if (action?.payload) {
          // Добавляем пользователя
          state.orders = action.payload;
          state.loadingStatus = 'success';
          state.error = null;
        }
      })
      // Вызывается в случае ошибки
      .addCase(getFilteredOrders.rejected, (state, action) => {
        console.log('error');
        state.loadingStatus = 'failed';
        state.error = action.error;
        state.orders = [];
      });
  },
});
